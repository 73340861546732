import React from "react";
import SelectBox from "../../ui/selectBox/SelectBox";
import SharedStyles from '../../../css/sales/shared.module.scss';

const DepositeHeader = ({ universitiesState, updateFilterHandler, depositesState, filterValues, onAdd, displayy }) => {
  return (
    <header className={`${SharedStyles["deposite-header"]}`} style={{display:displayy ? 'none' : '' }}>
      <div className={`${SharedStyles["selectboxes-div"]}`}>
        <SelectBox
          selectedOption={filterValues.university}
          name="university"
          isDisabled={universitiesState.state === "LOADING"}
          options={universitiesState.universities ?? []}
          onChange={updateFilterHandler}
          moreWidth={true}
          onChange2={onAdd}
        />
        <SelectBox
          selectedOption={filterValues.year}
          name="year"
          isDisabled={!filterValues.university.trueValue}
          options={[{ name: "2023년" }, { name: "2024년" }, { name: "2025년" }]}
          onChange={updateFilterHandler}
        />
        <SelectBox
          selectedOption={filterValues.month}
          name="month"
          isDisabled={!filterValues.year.trueValue}
          options={Array.from({ length: 12 }, (_, index) => ({ name: `${index + 1}월` }))}
          onChange={updateFilterHandler}
        />
      </div>
    </header>
  );
};

export default DepositeHeader;
