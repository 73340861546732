import React, { useContext } from "react";
import MemberContext from "../../../store/Member";
import PatentContext from "../../../store/Patent";
import DatePickerAndFileRow from "./common/DatePickerAndFileRow";
import DatePickerRow from "./common/DatePickerRow";
import ProcessStep from "./common/ProcessStep";
import typeName from "./constrant/typeName";

const FormSelfCorrecting = ({
  onClickDownPopShow,
  onClickUpPopShow,
  onChangeDatePicker,
  onOpenReport,
  isCheckpointsExists = () => false
}) => {
  const { auth, manage_company_idx } = useContext(MemberContext);
  const patentInfo = useContext(PatentContext);
  const isCompany = window.$Global.checkAuth("특허회사", auth);
  const isAgent = window.$Global.checkAuth("대리인", auth);
  return (
    <div className="form" style={{ margin: "61px 0" }}>
      <div className="field">
        {isCheckpointsExists({ mainCategory: '자진 보정 Check', subCategory: '' }) &&
          <button style={{ top: '55px', left: '-11px' }} onClick={() => onOpenReport({ mainCategory: '자진 보정 Check', subCategory: '' })} className="checkpoint-btn">
            <i className="icon_check_circle" />
            <span>자진 보정 Check</span>
          </button>
        }
        <ProcessStep text="자진 보정" state={patentInfo.state} />
        <div className="rows">
          <DatePickerRow
            title="자진보정기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: '자진 보정 Check', subCategory: '자진보정기한' })}
            onOpenReport={() => onOpenReport({ mainCategory: '자진 보정 Check', subCategory: '자진보정기한' })}
            condition={patentInfo.editing}
            data={patentInfo.self_correcting_deadline}
            dataKey="self_correcting_deadline"
            onChangeDatePicker={onChangeDatePicker}
          />
          <DatePickerAndFileRow
            title="자진 보정일"
            withCheckpoint={isCheckpointsExists({ mainCategory: '자진 보정 Check', subCategory: '자진 보정일' })}
            onOpenReport={() => onOpenReport({ mainCategory: '자진 보정 Check', subCategory: '자진 보정일' })}
            condition={patentInfo.editing}
            data={patentInfo.self_correcting_at}
            dataKey="self_correcting_at"
            onChangeDatePicker={onChangeDatePicker}
            onClickDownPopShow={() =>
              onClickDownPopShow(
                isCompany
                  ? typeName.self_correcting
                  : typeName.agent_self_correcting
              )
            }
            onClickUpPopShow={() =>
              onClickUpPopShow(
                isCompany
                  ? typeName.self_correcting
                  : typeName.agent_self_correcting
              )
            }
            haveFile={Boolean(patentInfo.self_correcting_file_cnt)}
          />
        </div>
      </div>
    </div>
  );
};

export default FormSelfCorrecting;
