import Slider from "react-slick";
import DTop from "./DTop";
import DBox from "./Dbox";
import Treemap from "./Treemap";
import "./css/DDashboard.scss";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Bar, Doughnut, HorizontalBar, Line, Pie } from "react-chartjs-2";
import {
  patentStatus,
  patentStatusByYear,
  country,
  mainCountry,
  mainCountryByYear,
  techStatus,
  techEval,
  ipc,
  techTransfer,
  techTransfer2,
  annualIpc,
  percent,
  survival,
  survivalIpc,
  survivalPatent,
  floating,
  doubleLine,
  allYearStatusOptions,
  annualOption,
  annualOption2,
  annualOption3,
  patentStatusRegion,
} from "./chartData";
import colors from "./colors";
import CustomSlide from "./CustomSlide";
import HeatMap from "../statistic/ipStatistic/HeatMap";
import Researcher from "../statistic/ipStatistic/Researcher";
import Annuity from "./Annuity";
import DistributionStatus from "../statistic/ipStatistic/DistributionStatus";
import WorldMapChart from "./WorldMapChart";
import KoreaMap from "./KoreaMap";
import ToggleSlide from "./ToggleSlide";
import { useCallback, useEffect, useRef, useState } from "react";
import PatentAPI from "../../API/patent";
import StaticAPI from "../../API/statistic";
import DistributionPop from "../statistic/DistributionPop";
import ContentLayout from "./ContentLayout";
import Loading from "../loading/Loading";
import InfoTable from "./InfoTable";
import MiniLoading from "../loading/MiniLoading";
import NetworkGraph from "./NetworkGraph";
import Pagination from "../common/Pagination";

const CURRENT_YEAR = new Date().getFullYear(); // 현재 xxxx 년도

const DDashboard = () => {
  const ten_Years = Array.from({ length: 10 }, (_, i) => CURRENT_YEAR - 1 - i).reverse(); // 현재 연도부터 10년 전까지의 연도 생성
  const memberIdx = sessionStorage.getItem("idx");
  const chartHeight = 250;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: false,
    adaptiveHeight: true,
  };

  const isFirstRender = useRef(true);
  const compName =
    sessionStorage.getItem("company_name")?.replace(/\s+/g, "") || "";
  const companyIdx = sessionStorage.getItem("company_idx") || "";
  const companyCode = sessionStorage.getItem("company_code") || "";
  const [statusData, setStatusData] = useState({});
  const [keyValueData, setKeyValueData] = useState({});
  const [isPopOpen, setIsPopOpen] = useState(false);
  const [patentInfo, setPatentInfo] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [patentPage, setPatentPage] = useState(1);
  const [category, setCategory] = useState("keyword");
  const [showPopup, setShowPopup] = useState(false);
  const [inventorName, setInventorName] = useState("");
  const [patentStatus, setPatentStatus] = useState("");
  const [isOpenPopReset, setIsOpenPopReset] = useState(false);
  const [findName, setFindName] = useState("");
  const [selectedIpc, setSelectedIpc] = useState({
    name: "",
    ipc: "",
  });
  const [viewData, setViewData] = useState({
    nation_string: "",
    apply_number: "",
    document_number: "",
  });
  const [viewDetailModalData, setViewDetailModalData] = useState({
    nation_string: "",
    apply_number: "",
    lt_number: "",
  });
  const [staticParams, setStaticParams] = useState({
    strValue: "",
    company_idx: companyIdx,
    flag: category,
    company_code: companyCode,
    year: "",
    subFlag: "",
    page: 1,
    count: 10,
    num: 0,
    level: "",
  });

  const [compCode,setCompcode] = useState("");
  const [detailsPage, setDetailsPage] = useState(1);
  const [isListModal, setIsListModal] = useState(false);
  const [isDetailModal, setIsDetailModal] = useState(false);
  const [isGraphModal, setIsGraphModal] = useState(false);
  const [modalGraphCode, setModalGraphCode] = useState("");
  const [modalGraphName, setModalGraphName] = useState("");
  const [modalName, setModalName] = useState("");
  const [selectYear, setSelectYear] = useState(CURRENT_YEAR - 1);
  const [inventorCount, setInventorCount] = useState(10);
  const [searchType, setSearchType] = useState("");
  const [count, setCount] = useState(50); // 주요발명자 한 페이지당 개수
  //================ data ================
  const [allPatentStatus, setAllPatentStatus] = useState({
    chartData: [],
    tableData: {},
    totalCnt: 0,
  }); // 전체 특허 현황
  const [regionData, setRegionData] = useState({
    chartData: [],
    tableData: {},
  }); // 국내외 특허현황
  const [ipcStatus, setIpcStatus] = useState({
    chartData: [],
    tableData: {},
    ipcDescTableData: {},
  }); // 전체 연도별 현황(증감)
  const [allYearStatus, setAllYearStatus] = useState({
    chartData: {},
    tableData: {},
  });
  const [interYearStatus, setInterYearStatus] = useState({
    chartData: {},
    tableData: {},
  });
  const [inventorData, setInventorData] = useState({}); // 주요 발명자 현황 데이터
  const [inventorByYear, setInventorByYear] = useState({
    chartData: {},
  }); // 발명자별 연도별 출원건수
  const [ipcRightData, setIpcRightData] = useState({
    chartData: {},
    tableData: {},
  }); // 국내 기술이전 통계 -  기술분야
  const [rightAliveData, setRightAliveData] = useState({
    barChartData: {},
    pieChartData: {},
    tableData: {},
  }); // 기술이전된 특허의 현황
  const [rightData, setRightData] = useState({
    chartData: {},
    tableData: {},
  }); // 전체 누적 기술이전 건수
  const [koreaData, setKoreaData] = useState({
    chartData: {},
    tableData: {},
  }); // 지역별 누적 기술이전 현황 지도 데이터
  const [top3Data, setTop3Data] = useState({
    chartData: {},
  }); // 기술이전 수가 많은 데이터
  // const [top10Data, setTop10Data] = useState([]); // 연도별 기술이전(양도) 수가 많은 대학&특허 연도별 상위 10 대학 데이터
  // const [transferDetails, setTransferDetails] = useState({}); // 연도별 기술이전(양도) 수가 많은 대학&특허 기술이전 건의 상세한 정보 데이터
  const [ipcCompData, setIpcCompData] = useState([]); // 기업 Ipc별 건수 데이터
  const [nearbyOransData, setNearbyOransData] = useState([]) // 기술이전된 주변 기관 데이터
  const [modalIpcCompData, setModalIpcCompData] = useState([]); // 모달 기업 Ipc별 건수 데이터
  const [modalNearbyOransData, setModalNearbyOransData] = useState([]) // 모달 기술이전된 주변 기관 데이터
  const [gradeAllData, setGradeAllData] = useState({
    chartData: {},
    tableData: {},
    levels: {},
  }); // 기술 평가 현황
  const [gradeYearData, setGradeYearData] = useState({
    chartData: {},
    tableData: {},
  }); // 연도별 특허 등급
  const [allCumulativeYearStatus, setAllCumulativeYearStatus] = useState({
    chartData: {},
    tableData: {},
  }); // 연도별 국내 누적 현황
  const [interCumulativeYearStatus, setInterCumulativeYearStatus] = useState({
    chartData: {},
    tableData: {},
  }); // 연도별 해외 누적 현황
  const [gradeYearInterData, setGradeYearInterData] = useState({
    chartData: {},
    tableData: {},
  }); // 해외 출원건의 연도별 특허 등급
  const [ipcYearData, setIpcYearData] = useState({
    chartData: {},
    tableData: {},
    tableData2: {},
  }); // IPC별 특허 등급
  const [ipcInterYearData, setIpcInterYearData] = useState({
    chartData: {},
    tableData: {},
  }); // 해외 출원 건의 IPC별 특허 등급
  const [aliveGradeData, setAliveGradeData] = useState({
    chartData: {},
    tableData: {},
  }); // 등록특허의 수명
  const [aliveAvgData, setAliveAvgData] = useState({
    chartData: {},
    tableData: {},
  }); // 출원년도별 등록특허의 수명
  const [aliveDurationData, setAliveDurationData] = useState({
    chartData: {},
    tableData: {},
  }); // 등록/소멸 소요시간
  const [aliveIPCMatrixData, setAliveIPCMatrixData] = useState({
    chartData: {},
    tableData: {},
  }); // 생존특허의 발명자*IPC 매트릭스
  const [aliveGradeMatrixData, setAliveGradeMatrixData] = useState({
    chartData: {},
    tableData: {},
  }); // 생존특허의 발명자*특허등급 매트릭스
  const [aliveRate, setAliveRate] = useState(0); // 등록특허 생존율
  const [aliveRegisterAliveByYear, setAliveRegisterAliveByYear] = useState({
    chartData: {},
    tableData: {},
  }); // 등록 특허의 연차별 생존율
  const [registerPatentByYear, setRegisterPatentByYear] = useState({
    chartData: {},
    tableData: {},
  }); // 연도별 등록특허 현황
  const [registerGiveUpData, setRegisterGiveUpData] = useState({
    chartData: {},
    tableData: {},
  }); // 등록 특허의 포기(소멸) 현황(연도별)
  const [registerMaintenanceByYear, setRegisterMaintenanceByYear] = useState({
    chartData: {},
    tableData: {},
  }); // 등록 연차별 특허현황
  const [mainApplyData, setMainApplyData] = useState({
    chartData: {},
    tableData: {},
  }); // 주요국 출원 현황
  const [mainApplyYearData, setMainApplyYearData] = useState({
    chartData: {},
    tableData: {},
  }); // 주요국의 연도별 출원현황(최근 5개년)
  const [mainApplyMatrixData, setMainApplyMatrixData] = useState({
    chartData: {
      xLabels: [],
      yLabels: [],
      data: [],
    },
  }); // 주요국의 연도별 출원현황(최근 5개년) 매트릭스
  const [mainApplyIPCData, setMainApplyIPCData] = useState({
    chart: {},
    worldmap: {},
  }); // 국가별 출원 현황 세계지도 데이터
  const [annualPayNextYear, setannualPayNextYear] = useState({
    cost: 0,
    year: new Date().getFullYear() + 1,
  }); // 예상 연차료 현황
  const [annualPayByYear, setAnnualPayByYear] = useState({
    chartData: {},
    tableData: {},
  }); // 연도별 예상 연차료
  const [annualPayByYearDetail, setAnnualPayByYearDetail] = useState({
    chartData: {},
    tableData: {},
  }); // 연도별 예상 연차료 상세
  const [annualPayByIpc, setAnnualPayByIpc] = useState({
    chartData: {},
    tableData: {},
  }); // ipc별 예상 연차료
  const [annualPayByGrade, setAnnualPayByGrade] = useState({
    chartData: {},
    tableData: {},
    maxMinValue: {},
  }); // 기술등급별 예상 연차료
  const [annualPayByGlobal, setAnnualPayByGlobal] = useState({
    chartData: {},
    tableData: {},
    maxMinValue: {},
  }); // 기술등급별 예상 연차료
  const [allApplyData, setAllApplyData] = useState([]); // 분포현황의 키워드 선택된거 없을때 차트에 보일 데이터

  //================ // data ================

  // 로딩바
  const [loadingStates, setLoadingStates] = useState({
    isLoadingAllPatentStatus: false,
    isLoadingRegionData: false,
    isLoadingIpcStatus: false,
    isLoadingAllYearStatus: false,
    isLoadingInterYearStatus: false,
    isLoadingAllCumulativeYearStatus: false,
    isLoadingInterCumulativeYearStatus: false,
    isLoadingInventorData: false,
    isLoadingIpcRightData: false,
    isLoadingRightData: false,
    isLoadingRightAliveData: false,
    isLoadingKoreaData: false,
    isLoadingTransferTopData: false,
    isLoadingTransferIpcData: false,
    isLoadingComTransferInfoData: false,
    isLoadingGradeAllData: false,
    isLoadingGradeYearData: false,
    isLoadingGradeYearInterData: false,
    isLoadingIpcYearData: false,
    isLoadingIpcInterYearData: false,
    isLoadingAliveGradeData: false,
    isLoadingAliveAvgData: false,
    isLoadingAliveDurationData: false,
    isLoadingAliveIPCMatrixData: false,
    isLoadingAliveGradeMatrixData: false,
    isLoadingAliveRegisterAliveByYear: false,
    isLoadingRegisterPatentByYear: false,
    isLoadingRegisterMaintenanceByYear: false,
    isLoadingRegisterGiveUpData: false,
    isLoadingMainApplyData: false,
    isLoadingMainApplyYearData: false,
    isLoadingMainApplyMatrixData: false,
    isLoadingMainApplyIPCData: false,
    isLoadingAnnualPayNextYear: false,
    isLoadingAnnualPayByYear: false,
    isLoadingAnnualPayByYearDetail: false,
    isLoadingAnnualPayByIpc: false,
    isLoadingAnnualPayByGrade: false,
    isLoadingAnnualPayByGlobal: false,
  });

  const setLoading = (key, value) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  };

  const getLegalData = () => {
    PatentAPI.getStatus({ flag: 1 }).then((res) => {
      if (res.data?.statusData) {
        setStatusData(res.data.statusData);
      }
    });
  };

  const getKeyValueData = () => {
    PatentAPI.getGrade({ apply_at: 2014 }).then((res) => {
      if (res.data?.result) {
        setKeyValueData(res.data.result);
      }
    });
  };

  const closePatentPop = () => {
    setIsPopOpen(false);
  };

  const getPatentListResearcher = (name, page = 1) => {
    if (!name) return; // Do not proceed if strValue (name) is empty

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "inventor",
      strValue: name,
    };

    setStaticParams(params);
    setSearchType("researcher");
    getPatentList(params);
  };

  const getPatentByIpcInvestor = (name, ipc, page = 1) => {
    if (!name) return;
    if (!ipc) return;

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "inventor",
      subFlag: "ipc",
      strValue: name,
      subStrValue: ipc,
    };

    setStaticParams(params);
    setSearchType("ipc");
    getPatentList(params);
  };

  const getPatentListStatus = (status, page = 1) => {
    if (!status) return;

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "status",
      strValue: status,
    };

    setStaticParams(params);
    setSearchType("status");
    getPatentList(params);
  };

  const getPatentListBySubmit = (year, page = 1) => {
    if (!year) return;

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "patent",
      strValue: "공개",
      year: year,
    };

    setStaticParams(params);
    setSearchType("submit");
    getPatentList(params);
    setIsPopOpen(true);
  };

  const getPatentBySmart = (num, year, level, page = 1) => {
    if (!num) return;

    setIsLoading(true);
    const params = {
      count: inventorCount,
      page: page,
      company_idx: companyIdx,
      company_code: companyCode,
      flag: "smart",
      strValue: level,
      year: year,
      subFlag: "",
      num: num,
      level: level,
    };

    setStaticParams(params);
    setSearchType("smart");
    getPatentList(params);
    setIsPopOpen(true);
  };

  const getPatentList = (params) => {
    PatentAPI.getPatentStatic(params)
      .then((res) => {
        if (res.status === 200) {
          setPatentInfo(res.data || {});
        }
        setIsLoading(false);
      })
      .catch((err) => {
        console.error("err:", err);
        setIsLoading(false);
      });
  };

  const getStatistic = () => {
    setIsLoading(true);
    setLoading("isLoadingAllPatentStatus", true);
    setLoading("isLoadingRegionData", true);
    setLoading("isLoadingIpcStatus", true);
    setLoading("isLoadingAllYearStatus", true);
    setLoading("isLoadingInterYearStatus", true);
    setLoading("isLoadingAllCumulativeYearStatus", true);
    setLoading("isLoadingInterCumulativeYearStatus", true);
    StaticAPI.getPatentByYear({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getPatentByYear:", res);
        if (res.status === 200) {
          setAllPatentStatus(() => ({
            chartData: res.data?.allStatus?.chartData || [],
            tableData: res.data?.allStatus?.tableData || [],
            totalCnt: res.data?.allStatus?.totalCnt || 0,
          }));
          setRegionData({
            chartData:
              {
                labels: res.data?.regionStatus?.regionLabels || [],
                datasets:
                  res.data?.regionStatus?.chartData.map((dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                  })) || [],
              } || {},
            tableData: res.data?.regionStatus?.tableData || {},
          });
          setIpcStatus({
            chartData:
              {
                labels: res.data?.ipcStatus?.yearLabels || [],
                datasets:
                  res.data?.ipcStatus?.chartData?.datasets[0].map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.ipcStatus?.tableData || {},
            ipcDescTableData:
              {
                headers: res.data?.ipcStatus?.ipcDescTableData?.headers,
                rows: res.data?.ipcStatus?.ipcDescTableData?.rows,
                ipcDesc: true,
              } || {},
          });
          setAllYearStatus({
            chartData:
              {
                labels: res.data?.allYearStatus?.chartData?.labels || [],
                datasets:
                  res.data?.allYearStatus?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.allYearStatus?.tableData || {},
          });
          setInterYearStatus({
            chartData:
              {
                labels: res.data?.interYearStatus?.chartData?.labels || [],
                datasets:
                  res.data?.interYearStatus?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                    })
                  ) || [],
              } || {},
            tableData: res.data?.interYearStatus?.tableData || {},
          });
          setAllCumulativeYearStatus({
            chartData:
              {
                labels:
                  res.data?.allCumulativeYearStatus?.chartData?.labels || [],
                datasets:
                  res.data?.allCumulativeYearStatus?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      barThickness: 30,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.allCumulativeYearStatus?.tableData || {},
          });
          setInterCumulativeYearStatus({
            chartData:
              {
                labels:
                  res.data?.interCumulativeYearStatus?.chartData?.labels || [],
                datasets:
                  res.data?.interCumulativeYearStatus?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      barThickness: 30,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.interCumulativeYearStatus?.tableData || {},
          });
          setAllApplyData(res.data?.allApplyData || []);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingAllPatentStatus", false);
        setLoading("isLoadingRegionData", false);
        setLoading("isLoadingIpcStatus", false);
        setLoading("isLoadingAllYearStatus", false);
        setLoading("isLoadingInterYearStatus", false);
        setLoading("isLoadingAllCumulativeYearStatus", false);
        setLoading("isLoadingInterCumulativeYearStatus", false);
      });
  };

  const getAnnualPayData = () => {
    setIsLoading(true);
    setLoading("isLoadingAnnualPayNextYear", true);
    setLoading("isLoadingAnnualPayByYear", true);
    setLoading("isLoadingAnnualPayByYearDetail", true);
    setLoading("isLoadingAnnualPayByIpc", true);
    setLoading("isLoadingAnnualPayByGrade", true);
    setLoading("isLoadingAnnualPayByGlobal", true);
    StaticAPI.getAnnualPayData({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getAnnualPayData:", res);
        if (res.status === 200) {
          setannualPayNextYear({
            cost: res.data?.annualPayNextYear?.cost || 0,
            year:
              res.data?.annualPayNextYear?.year || new Date().getFullYear() + 1,
          });
          setAnnualPayByYear({
            chartData:
              {
                labels: res.data?.annualPayByYear?.chartData?.labels || [],
                datasets:
                  res.data?.annualPayByYear?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                    })
                  ) || [],
              } || {},
            tableData: {
              ...res.data?.annualPayByYear?.tableData,
              rows:
                res.data?.annualPayByYear?.tableData?.rows.map((row) => [
                  row[0],
                  window.$Global.commaify(row[1]),
                ]) || [],
            },
          });
          setAnnualPayByYearDetail({
            chartData:
              {
                labels:
                  res.data?.annualPayByYearDetail?.chartData?.labels || [],
                datasets:
                  res.data?.annualPayByYearDetail?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: {
              ...res.data?.annualPayByYearDetail?.tableData,
              rows:
                res.data?.annualPayByYearDetail?.tableData?.rows.map((row) => [
                  row[0],
                  window.$Global.commaify(row[1]),
                  window.$Global.commaify(row[2]),
                  window.$Global.commaify(row[3]),
                ]) || [],
            },
          });
          setAnnualPayByIpc({
            chartData:
              {
                labels: res.data?.annualPayByIpc?.chartData?.labels || [],
                datasets:
                  res.data?.annualPayByIpc?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: {
              ...res.data?.annualPayByIpc?.tableData,
              rows:
                res.data?.annualPayByIpc?.tableData?.rows.map((row) => [
                  row[0],
                  ...row.slice(1).map((num) => window.$Global.commaify(num)),
                ]) || [],
            },
          });
          setAnnualPayByGrade({
            chartData:
              {
                labels: res.data?.annualPayByGrade?.chartData?.labels || [],
                datasets:
                  res.data?.annualPayByGrade?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                    })
                  ) || [],
              } || {},
            tableData: {
              ...res.data?.annualPayByGrade?.tableData,
              rows:
                res.data?.annualPayByGrade?.tableData?.rows.map((row) => [
                  row[0],
                  window.$Global.commaify(row[1]),
                  row[2],
                  window.$Global.commaify(row[3]),
                ]) || [],
            },
          });
        }
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingAnnualPayNextYear", false);
        setLoading("isLoadingAnnualPayByYear", false);
        setLoading("isLoadingAnnualPayByYearDetail", false);
        setLoading("isLoadingAnnualPayByIpc", false);
        setLoading("isLoadingAnnualPayByGrade", false);
        setLoading("isLoadingAnnualPayByGlobal", false);
      });
  };

  const getPatentNation = () => {
    setIsLoading(true);
    setLoading("isLoadingMainApplyData", true);
    setLoading("isLoadingMainApplyYearData", true);
    setLoading("isLoadingMainApplyMatrixData", true);
    setLoading("isLoadingMainApplyIPCData", true);
    StaticAPI.getPatentNation({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getPatentNation:", res);
        setMainApplyData({
          chartData:
            {
              labels: res.data?.mainApplyData?.chartData?.labels || [],
              datasets:
                [
                  {
                    data: res.data?.mainApplyData?.chartData?.datasets?.data?.map(
                      (value) => parseFloat(value)
                    ),
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                    borderWidth: 0,
                  },
                ] || [],
            } || {},
          tableData: res.data?.mainApplyData?.tableData || {},
        });
        setMainApplyYearData({
          chartData:
            {
              labels: res.data?.mainApplyYearData?.chartData?.labels || [],
              datasets:
                res.data?.mainApplyYearData?.chartData?.datasets.map(
                  (dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                    barThickness: 30,
                  })
                ) || [],
            } || {},
          tableData: res.data?.mainApplyYearData?.tableData || {},
        });
        setMainApplyMatrixData({
          chartData: {
            xLabels: res.data?.mainApplyMatrixData?.chartData?.xLabels || [],
            yLabels: res.data?.mainApplyMatrixData?.chartData?.yLabels || [],
            data: res.data?.mainApplyMatrixData?.chartData?.data || [],
          },
        });
        setMainApplyIPCData({
          chart: (res.data?.mainApplyIPCData?.chart || []).map((item, idx) => {
            return {
              nation: item.nation,
              chartData: {
                labels: item.chartData.labels,
                datasets: item.chartData.datasets.map((data, datasetIndex) => ({
                  ...data,
                  backgroundColor: data.data.map(
                    (_, dataIndex) => colors[dataIndex % colors.length]
                  ),
                })),
              },
            };
          }),
          worldmap: res.data?.mainApplyIPCData?.worldmap || [],
        });

        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingMainApplyData", false);
        setLoading("isLoadingMainApplyYearData", false);
        setLoading("isLoadingMainApplyMatrixData", false);
        setLoading("isLoadingMainApplyIPCData", false);
      });
  };

  const getAlive = () => {
    setIsLoading(true);
    setLoading("isLoadingAliveGradeData", true);
    setLoading("isLoadingAliveAvgData", true);
    setLoading("isLoadingAliveDurationData", true);
    setLoading("isLoadingAliveIPCMatrixData", true);
    setLoading("isLoadingAliveGradeMatrixData", true);
    setLoading("isLoadingAliveRegisterAliveByYear", true);
    setLoading("isLoadingRegisterGiveUpData", true);
    setLoading("isLoadingRegisterMaintenanceByYear", true);
    setLoading("isLoadingRegisterPatentByYear", true);
    StaticAPI.getAlive({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getAlive:", res);
        if (res.status === 200) {
          setAliveGradeData({
            chartData:
              {
                labels: res.data?.aliveGradeData?.chartData?.labels || [],
                datasets:
                  res.data?.aliveGradeData?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.aliveGradeData?.tableData || {},
          });
          setAliveAvgData({
            chartData:
              {
                labels: res.data?.aliveAvgData?.chartData?.labels || [],
                datasets:
                  res.data?.aliveAvgData?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.aliveAvgData?.tableData || {},
          });

          setAliveDurationData({
            chartData:
              {
                labels: res.data?.aliveDurationData?.chartData?.labels || [],
                datasets:
                  res.data?.aliveDurationData?.chartData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[index % colors.length],
                      borderColor: colors[index % colors.length],
                      fill: false,
                      tension: 0,
                    })
                  ) || [],
              } || {},
            tableData: res.data?.aliveDurationData?.tableData || {},
          });
          setAliveRate(res.data?.aliveRateData?.rate || 0);
          setAliveIPCMatrixData({
            chartData: res.data?.aliveIPCMatrixData?.chartData || {},
          });
          setAliveGradeMatrixData({
            chartData: res.data?.aliveGradeMatrixData?.chartData || {},
          });
          setAliveRegisterAliveByYear({
            chartData: {
              labels: res.data?.aliveRegisterAliveByYear?.chartData?.labels,
              units: res.data?.aliveRegisterAliveByYear?.chartData?.units,
              datasets:
                res.data?.aliveRegisterAliveByYear?.chartData?.datasets?.map(
                  (dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                    borderColor: colors[index % colors.length],
                    fill: false,
                    tension: 0,
                    barThickness: 30,
                  })
                ),
            },
            tableData: res.data?.aliveRegisterAliveByYear?.tableData,
          });
          setRegisterPatentByYear({
            chartData: {
              labels: res.data?.registerPatentByYear?.chartData?.labels,
              units: res.data?.registerPatentByYear?.chartData?.units,
              datasets:
                res.data?.registerPatentByYear?.chartData?.datasets?.map(
                  (dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                    borderColor: colors[index % colors.length],
                    fill: false,
                    tension: 0,
                    barThickness: 30,
                  })
                ),
            },
            tableData: res.data?.registerPatentByYear?.tableData,
          });
          setRegisterGiveUpData({
            chartData: {
              labels: res.data?.registerGiveUpData?.chartData?.labels,
              units: res.data?.registerGiveUpData?.chartData?.units,
              datasets: res.data?.registerGiveUpData?.chartData?.datasets?.map(
                (dataset, index) => ({
                  ...dataset,
                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                  barThickness: 30,
                })
              ),
            },
            tableData: res.data?.registerGiveUpData?.tableData,
          });
          setRegisterMaintenanceByYear({
            chartData: {
              labels: res.data?.registerMaintenanceByYear?.chartData?.labels,
              units: res.data?.registerMaintenanceByYear?.chartData?.units,
              datasets:
                res.data?.registerMaintenanceByYear?.chartData?.datasets?.map(
                  (dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                    borderColor: colors[index % colors.length],
                    barThickness: 30,
                  })
                ),
            },
            tableData: res.data?.registerMaintenanceByYear?.tableData,
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingAliveGradeData", false);
        setLoading("isLoadingAliveAvgData", false);
        setLoading("isLoadingAliveDurationData", false);
        setLoading("isLoadingAliveIPCMatrixData", false);
        setLoading("isLoadingAliveGradeMatrixData", false);
        setLoading("isLoadingAliveRegisterAliveByYear", false);
        setLoading("isLoadingRegisterGiveUpData", false);
        setLoading("isLoadingRegisterMaintenanceByYear", false);
        setLoading("isLoadingRegisterPatentByYear", false);
      });
  };

  const getRightTransfer = () => {
    setIsLoading(true);
    setLoading("isLoadingIpcRightData", true);
    setLoading("isLoadingRightData", true);
    setLoading("isLoadingRightAliveData", true);
    setLoading("isLoadingKoreaData", true);
    StaticAPI.getRightTransfer({
      member: memberIdx,
      apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getRightTransfer:", res);
        if (res.status === 200) {
          setIpcRightData({
            chartData:
              {
                labels: res.data?.ipcRightData?.chartData?.labels || [],
                datasets:
                  [
                    {
                      data: res.data?.ipcRightData?.chartData?.datasets[0]
                        ?.data,
                      backgroundColor: colors,
                      hoverBackgroundColor: colors,
                      borderWidth: 0,
                    },
                  ] || [],
              } || {},
            tableData: res.data?.ipcRightData?.tableData || {},
          });
          setRightData({
            chartData:
              {
                labels: res.data?.rightData?.chartData?.rightData?.labels || [],
                datasets:
                  res.data?.rightData?.chartData?.rightData?.datasets.map(
                    (dataset, index) => ({
                      ...dataset,
                      backgroundColor: colors[7],
                      borderColor: colors[0],
                      barThickness: 30,
                      order: 2,
                      yAxisID: "y-axis-1",
                      ...(dataset.type === "line" && {
                        fill: false,
                        tension: 0,
                        order: 1,
                      }),
                    })
                  ) || [],
              } || {},
            tableData: res.data?.rightData?.tableData || {},
          });
          setRightAliveData({
            barChartData: {
              labels: res.data?.rightAliveData?.barChartData?.labels || [],
              datasets:
                [
                  {
                    data: res.data?.rightAliveData?.barChartData?.datasets[0]
                      ?.data,
                    label:
                      res.data?.rightAliveData?.barChartData?.datasets[0]
                        ?.label,
                    backgroundColor: colors[0],
                    hoverBackgroundColor: colors[0],
                    barThickness: 30,
                  },
                ] || [],
            },
            pieChartData: {
              labels: res.data?.rightAliveData?.pieChartData?.labels || [],
              datasets:
                [
                  {
                    data: res.data?.rightAliveData?.pieChartData?.datasets[0]
                      ?.data,
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                  },
                ] || [],
            },
          });
          setKoreaData({
            chartData: res.data?.rightKoreanMapData?.koreanMap.map(
              (data, index) => ({
                ...data,
                chartData: {
                  labels: data?.chartData.labels,
                  datasets: [
                    {
                      data: data?.chartData?.datasets[0]?.data,
                      backgroundColor: [
                        "rgba(255, 192, 172, 0.9)",
                        "rgba(149, 176, 242, 0.9)",
                      ],
                      borderWidth: 0,
                    },
                  ],
                },
              })
            ),
            tableData: res.data?.rightKoreanMapData?.tableData,
          });
          setTop3Data({
            chartData: 
            {
              labels: res.data?.rightTopData?.chartData?.labels || [],
              datasets:
                res.data?.rightTopData?.chartData?.datasets.map(
                  (dataset, index) => ({
                    ...dataset,
                    backgroundColor: colors[index % colors.length],
                    borderColor: colors[index % colors.length],
                    barThickness: 10,
                    order: 2,
                    yAxisID: "y-axis-1",
                    ...(dataset.type === "line" && {
                      fill: false,
                      tension: 0,
                      order: 1,
                    }),
                  })
                ) || [],
            } || {},
          })
        }
        //console.log("getRightTransfer:", res);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingIpcRightData", false);
        setLoading("isLoadingRightData", false);
        setLoading("isLoadingRightAliveData", false);
        setLoading("isLoadingKoreaData", false);
      });
  };

  // 연도별 기술이전(양도) 수가 많은 대학&특허
  // const getTransferTop = () => {
  //   setIsLoading(true);
  //   setLoading("isLoadingTransferTopData", true);
  //   StaticAPI.getTransferTop({
  //     member: memberIdx,
  //     apply_at: selectYear,
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setTop10Data(
  //             res.data?.rightTop10Data?.tableData?.rows || [],
  //         )
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("API call failed with error:", error);
  //       setIsLoading(false);
  //     })
  //     .finally(() => {
  //       setLoading("isLoadingTransferTopData", false);
  //     });
  // };

  // 연도별 기술이전(양도) 수가 많은 대학&특허
  // const getComTransferInfo = (c_code, page=1) => {
  //   setIsLoading(true);
  //   setLoading("isLoadingComTransferInfoData", true);
  //   StaticAPI.getComTransferInfo({
  //     member: memberIdx,
  //     company_code: c_code,
  //     apply_at: selectYear,
  //     page: page,
  //     count: 10,
  //   })
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setTransferDetails(
  //             res.data?.comRightData || [],
  //         )
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((error) => {
  //       console.error("API call failed with error:", error);
  //       setIsLoading(false);
  //     })
  //     .finally(() => {
  //       setLoading("isLoadingComTransferInfoData", false);
  //     });
  // }

  const getTransferIpc = (c_code = companyCode) => {
    setIsLoading(true);
    setLoading("isLoadingTransferIpcData", true);
    StaticAPI.getTransferIpc({
      member: memberIdx,
      company_code: c_code,
    })
      .then((res) => {
        if (res.status === 200) {
          isGraphModal ? setModalIpcCompData(
            res.data?.ipcCompanyData || [],
          ) :
          setIpcCompData(
              res.data?.ipcCompanyData || [],
          )
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingTransferIpcData", false);
      });
  }
// // 기업 기술이전 분야 네트워크- IPC
//   const getTransferor = (c_code = companyCode) => {
//     setIsLoading(true);
//     setLoading("isLoadingTransferorData", true);
//     StaticAPI.getTransferor({
//       member: memberIdx,
//       company_code: c_code,
//     })
//     .then((res)=> {
//       if(res.status === 200) {
//         isGraphModal ? setModalNearbyOransData(
//           res.data?.data || [],
//         ) : 
//           setNearbyOransData(
//             res.data?.data || [],
//         )
//       }
//       setIsLoading(false);
//     })
//     .catch((error) => {
//       console.error("API call failed with error:", error);
//       setIsLoading(false);
//     })
//     .finally(() => {
//       setLoading("isLoadingTransferorData", false);
//     });
//   }

  const getTechEval = () => {
    setIsLoading(true);
    setLoading("isLoadingGradeAllData", true);
    setLoading("isLoadingGradeYearData", true);
    setLoading("isLoadingGradeYearInterData", true);
    setLoading("isLoadingIpcYearData", true);
    setLoading("isLoadingIpcInterYearData", true);
    StaticAPI.getTechEval({
      member: memberIdx,
      flag: 1,
      apply_at: getYearsAgo(10),
      sub_apply_at: getYearsAgo(10),
    })
      .then((res) => {
        //console.log("getTechEval:", res);
        if (res.status === 200) {
          setGradeAllData({
            chartData:
              {
                labels: res.data?.gradeAllData?.chartData?.labels || [],
                datasets: [
                  {
                    data:
                      res.data?.gradeAllData?.chartData?.datasets[0]?.data ||
                      [],
                    backgroundColor: colors,
                    hoverBackgroundColor: colors,
                  },
                ],

              } || {},
            tableData: res.data?.gradeAllData?.tableData || {},
            levels: res.data?.gradeAllData?.levels || {},
          });

          setGradeYearData({
            chartData: {
              labels: res.data?.gradeYearData?.chartData?.labels || [],
              datasets: res.data?.gradeYearData?.chartData?.datasets.map(
                (dataset, index) => ({
                  ...dataset,
                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                  fill: false,
                  tension: 0,
                })
              ),
            },
            tableData: res.data?.gradeYearData?.tableData || {},
          });

          setGradeYearInterData({
            chartData: {
              labels: res.data?.gradeYearInterData?.chartData?.labels || [],
              datasets: res.data?.gradeYearInterData?.chartData?.datasets.map(
                (dataset, index) => ({
                  ...dataset,
                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                  fill: false,
                  tension: 0,
                })
              ),
            },
            tableData: res.data?.gradeYearInterData?.tableData || {},
          });

          setIpcYearData({
            chartData: {
              labels: res.data?.ipcYearData?.chartData?.labels || [],
              datasets: res.data?.ipcYearData?.chartData?.datasets.map(
                (dataset, index) => ({
                  ...dataset,
                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                  fill: false,
                  tension: 0,
                })
              ),
            },
            tableData: res.data?.ipcYearData?.tableData || {},
            tableData2: res.data?.ipcYearData?.tableData2 || {},
          });

          setIpcInterYearData({
            chartData: {
              labels: res.data?.ipcInterYearData?.chartData?.labels || [],
              datasets: res.data?.ipcInterYearData?.chartData?.datasets.map(
                (dataset, index) => ({
                  ...dataset,
                  backgroundColor: colors[index % colors.length],
                  borderColor: colors[index % colors.length],
                  fill: false,
                  tension: 0,
                })
              ),
            },
            tableData: res.data?.ipcInterYearData?.tableData || {},
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("API call failed with error:", error);
        setIsLoading(false);
      })
      .finally(() => {
        setLoading("isLoadingGradeAllData", false);
        setLoading("isLoadingGradeYearData", false);
        setLoading("isLoadingGradeYearInterData", false);
        setLoading("isLoadingIpcYearData", false);
        setLoading("isLoadingIpcInterYearData", false);
      });
  };

  const getInventor = useCallback(async (newPage, initialLoad = false) => {
    try {
      const res = await PatentAPI.getInventor({
        apply_at: getYearsAgo(10),
        page: newPage,
        count: count,
      });
      if (res.status === 200) {
        setInventorData(res.data);
        setInventorByYear(res.data.inventorByYear);

        setInventorByYear({
          chartData: {
            labels: res.data?.inventorByYear?.chartData?.labels || [],
            datasets: res.data?.inventorByYear?.chartData?.datasets.map(
              (dataset, index) => ({
                ...dataset,
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                fill: false,
                tension: 0,
              })
            ),
          },
        });

        //console.log("getInventor:", res);
        return res.data;
      } else {
        return {};
      }
    } catch (error) {
      console.error("Error fetching inventor data:", error);
      return {};
    }
  }, []);

  useEffect(() => {
    isFirstRender.current = false;
    getLegalData();
    getKeyValueData();
    getStatistic();
    getInventor(1);
    getRightTransfer();
    // getTransferTop(); // 연도별 기술이전(양도) 수가 많은 대학&특허
    getTechEval();
    getAlive();
    getPatentNation();
    getAnnualPayData();
    // getTransferIpc(); // 기업 기술이전 분야 네트워크- IPC
    // getTransferor(); // 기업 기술이전분야 네트워크 - IPC
  }, []);

  useEffect(() => {
    if (!isFirstRender.current && !isOpenPopReset) {
      if (searchType === "researcher" && inventorName) {
        getPatentListResearcher(inventorName, patentPage);
      } else if (searchType === "ipc") {
        getPatentByIpcInvestor(selectedIpc.name, selectedIpc.ipc, patentPage);
      } else if (searchType === "submit" && staticParams.year) {
        getPatentListBySubmit(staticParams.year, patentPage);
      } else if (searchType === "smart" && staticParams.year) {
        getPatentBySmart(
          staticParams.num,
          staticParams.year,
          staticParams.strValue,
          patentPage
        );
      } else if (searchType === "status" && setPatentStatus) {
        getPatentListStatus(patentStatus, patentPage);
      }
    }
  }, [patentPage]);

  // 연도별 기술이전(양도) 수가 많은 대학&특허 연도
  // useEffect(() => {
  //   if (isFirstRender.current) {
  //     isFirstRender.current = false;
  //   } else if (isOpenPopReset) {
  //     setIsOpenPopReset(false);
  //   } else {
  //     getComTransferInfo(compCode ,detailsPage);
  //   }
  // }, [detailsPage]);
  
  // 연도별 기술이전(양도) 수가 많은 대학&특허 연도 선택 시, API 호출
  // useEffect(() => {
  //   getTransferTop();
  // },[selectYear])

// // 기업 기술이전 분야 네트워크- IPC
//   useEffect(() => {
//     if(isGraphModal && modalGraphCode) {
//       getTransferIpc(modalGraphCode);
//       getTransferor(modalGraphCode);
//     }
//   }, [isGraphModal, modalGraphCode]);

  const handleModalGraphClick = (node) => {
    setIsGraphModal(true);
    setModalGraphCode(node.idx);
    setModalGraphName(node.name);
  }

  const handlePatentPageChange = (page) => {
    setPatentPage(page);
  };
  
  const handleDetailsPageChagne = (page) => {
    setDetailsPage(page);
  }

  const handleResearcherClick = (name) => {
    setInventorName(name);
    setPatentPage(1);
    setSearchType("researcher");
    getPatentListResearcher(name, 1);
    setIsPopOpen(true);
  };

  const handleStatusClick = (status) => {
    setPatentStatus(status);
    setPatentPage(1);
    setSearchType("status");
    getPatentListStatus(status, 1);
    setIsPopOpen(true);
  };

  const handleIpcInventor = (data) => {
    setSelectedIpc({
      name: data.y,
      ipc: data.x,
    });
    setPatentPage(1);
    setSearchType("ipc");
    getPatentByIpcInvestor(data.y, data.x, 1);
    setIsPopOpen(true);
  };

  const handleOpenModal = (c_code) => {
    // getComTransferInfo(c_code);
    setCompcode(c_code);
    setIsListModal(true);
  };

  const getYearsAgo = (years) => {
    const yearsAgo = CURRENT_YEAR - years;
    return yearsAgo;
  };

  const findInventor = (name) => {
    handleResearcherClick(name);
  };

  const allPatentStatusClick = (data) => {
    handleStatusClick(data.g);
  };

  const handleClickIpcInvestor = (data) => {
    handleIpcInventor(data);
  };

  const [hovered, setHovered] = useState(false); // 마우스 오버 상태 관리
  const [hoveredField, setHoveredField] = useState(""); // 어떤 필드에 마우스가 올라왔는지
  const ipcSummary = (array) => {
    if (!Array.isArray(array) || array.length === 0) return "";
    return array.length === 1 ? array[0] : `${array[0]} 외 ${array.length - 1}개`;
  };
  
  const inventorSummary = (array) => {
    if (!Array.isArray(array) || array.length === 0) return "";
    return array.length === 1 ? array[0] : `${array[0]} 외 ${array.length - 1}명`;
  };
  

  return (
    <div id="DDashboard">
      <DTop />
      <div className="bg_gray">
        <div className="wrap">
          <p className="warn">
            <span>최근 10개년 특허 ({getYearsAgo(10)}년 1월 1일 ~ 현재)</span>
          </p>
          <div className="row row1">
            <DBox
              content={
                <ContentLayout
                  title="전체 특허 현황"
                  content={
                    loadingStates.isLoadingAllPatentStatus ? (
                      <MiniLoading
                        show={loadingStates.isLoadingAllPatentStatus}
                      />
                    ) : (
                      <>
                        <Treemap
                          chartData={allPatentStatus.chartData}
                          label="국내외 특허현황"
                          colors={colors}
                          isComma={true}
                          unit="건수"
                          onClickHandler={allPatentStatusClick}
                          cneterTxt={
                            <strong className="total_cnt">
                              총
                              {window.$Global.commaify(
                                allPatentStatus.totalCnt
                              )}
                              건 출원
                            </strong>
                          }
                        />
                      </>
                    )
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   <CustomSlide
                //     title="전체 특허 현황"
                //     content={
                //       loadingStates.isLoadingAllPatentStatus ? (
                //         <MiniLoading
                //           show={loadingStates.isLoadingAllPatentStatus}
                //         />
                //       ) : (
                //         <Treemap
                //           chartData={allPatentStatus.chartData}
                //           label="국내외 특허현황"
                //           colors={colors}
                //           isComma={true}
                //           unit="건수"
                //         />
                //       )
                //     }
                //   />
                //   {/* <CustomSlide
                //     title="전체 특허 현황"
                //     content={
                //       <HorizontalBar
                //         data={regionData.chartData}
                //         options={patentStatus.options}
                //         height={chartHeight}
                //         plugins={ChartDataLabels}
                //       />
                //     }
                //   />
                //   <CustomSlide
                //     title="전체 연도별 증감현황"
                //     content={
                //       <>
                //         <Line
                //           data={ipcStatus.chartData}
                //           options={patentStatusByYear.options}
                //           height={chartHeight}
                //         />
                //       </>
                //     }
                //   /> */}
                // </Slider>
              }
            />
            <DBox
              content={
                <ContentLayout
                  title="국가별 출원 현황"
                  content={
                    loadingStates.isLoadingMainApplyData ? (
                      <MiniLoading
                        show={loadingStates.isLoadingMainApplyData}
                      />
                    ) : (
                      <div className="world_box">
                        <div>
                          <p className="sub_tit">주요국 출원 현황</p>
                          <div>
                            <Pie
                              data={mainApplyData.chartData}
                              options={country.options}
                              height={chartHeight}
                              plugins={ChartDataLabels}
                            />
                          </div>
                        </div>
                        <div>
                          <p className="sub_tit">주요국의 IPC 출원 현황</p>
                          <WorldMapChart
                            colors={colors}
                            worldData={mainApplyIPCData}
                          />
                        </div>
                      </div>
                    )
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   {/* <CustomSlide
                //     title="전체 연도별 증감현황"
                //     content={
                //       <div className="world_box">
                //         <div>
                //           <Pie
                //             data={country.data}
                //             options={country.options}
                //             height={chartHeight}
                //           />
                //         </div>
                //         <div>
                //           <WorldMapChart colors={colors} />
                //         </div>
                //       </div>
                //     }
                //   /> */}
                //   <CustomSlide
                //     title="국가별 출원 현황"
                //     content={
                //       loadingStates.isLoadingMainApplyData ? (
                //         <MiniLoading
                //           show={loadingStates.isLoadingMainApplyData}
                //         />
                //       ) : (
                //         <div className="world_box">
                //           <div>
                //             <p className="sub_tit">주요국 출원 현황</p>
                //             <div>
                //               <Pie
                //                 data={mainApplyData.chartData}
                //                 options={country.options}
                //                 height={chartHeight}
                //               />
                //             </div>
                //           </div>
                //           <div>
                //             <p className="sub_tit">주요국의 IPC 출원 현황</p>
                //             <WorldMapChart
                //               colors={colors}
                //               worldData={mainApplyIPCData}
                //             />
                //           </div>
                //         </div>
                //       )

                //       // <Bar
                //       //   data={mainCountry.data}
                //       //   options={mainCountry.options}
                //       //   height={chartHeight}
                //       //   // plugins={ChartDataLabels}
                //       // />
                //     }
                //   />
                //   {/* <CustomSlide
                //     title="주요국 연도별 출원현황"
                //     content={
                //       <HeatMap
                //         xLabels={mainCountryByYear.xLabels}
                //         yLabels={mainCountryByYear.yLabels}
                //         data={mainCountryByYear.data}
                //       />
                //     }
                //   /> */}
                // </Slider>
              }
            />
          </div>
          <div className="row row2">
            <DBox
              content={
                <ContentLayout
                  title="기술 평가 현황"
                  content={
                    loadingStates.isLoadingGradeAllData ? (
                      <MiniLoading show={loadingStates.isLoadingGradeAllData} />
                    ) : (
                      <>
                        <div className="chart_box tech_level_chart_box">
                          <div className="tech_level_chart">
                            <div>
                              <Doughnut
                                data={gradeAllData.chartData}
                                options={techStatus.options}
                                height={chartHeight}
                                width={250}
                              />
                            </div>
                            {/* <div className="avg">
                              <p>
                                종합
                                <br />
                                특허등급
                              </p>
                              <strong>
                                {gradeAllData.levels["평균 특허 등급"]}
                              </strong>
                            </div> */}
                          </div>
                          <div className="levels">
                            <ul>
                              <li>
                                <p>활용성 등급</p>
                                <strong style={{ background: colors[0] }}>
                                  {gradeAllData.levels["평균 활용성 등급"]}
                                </strong>
                              </li>
                              <li>
                                <p>권리성 등급</p>
                                <strong style={{ background: colors[1] }}>
                                  {gradeAllData.levels["평균 권리성 등급"]}
                                </strong>
                              </li>
                              <li>
                                <p>기술성 등급</p>
                                <strong style={{ background: colors[2] }}>
                                  {gradeAllData.levels["평균 기술성 등급"]}
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </>
                    )
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   <CustomSlide
                //     title="기술 평가 현황"
                //     content={
                //       loadingStates.isLoadingGradeAllData ? (
                //         <MiniLoading
                //           show={loadingStates.isLoadingGradeAllData}
                //         />
                //       ) : (
                //         <>
                //           <div className="chart_box tech_level_chart_box">
                //             <div className="tech_level_chart">
                //               <div>
                //                 <Doughnut
                //                   data={gradeAllData.chartData}
                //                   options={techStatus.options}
                //                   height={chartHeight}
                //                   width={250}
                //                 />
                //               </div>
                //               <div className="avg">
                //                 <p>
                //                   평균
                //                   <br />
                //                   특허등급
                //                 </p>
                //                 <strong>
                //                   {gradeAllData.levels["평균 특허 등급"]}
                //                 </strong>
                //               </div>
                //             </div>
                //             <div className="levels">
                //               <ul>
                //                 <li>
                //                   <p>활용성 등급</p>
                //                   <strong style={{ background: colors[0] }}>
                //                     {gradeAllData.levels["평균 활용성 등급"]}
                //                   </strong>
                //                 </li>
                //                 <li>
                //                   <p>권리성 등급</p>
                //                   <strong style={{ background: colors[1] }}>
                //                     {gradeAllData.levels["평균 권리성 등급"]}
                //                   </strong>
                //                 </li>
                //                 <li>
                //                   <p>기술성 등급</p>
                //                   <strong style={{ background: colors[2] }}>
                //                     {gradeAllData.levels["평균 기술성 등급"]}
                //                   </strong>
                //                 </li>
                //               </ul>
                //             </div>
                //           </div>
                //         </>
                //       )

                //       // <div className="tech_status">
                //       //   <Doughnut
                //       //     data={techStatus.data}
                //       //     options={techStatus.options}
                //       //     height={chartHeight}
                //       //   />
                //       // </div>
                //     }
                //     techInfo={true} // 기술 평가 현황일때만 추가정보
                //     colors={colors}
                //   />
                //   {/* <CustomSlide
                //     title="연도별 특허등급(5개년)"
                //     content={
                //       <Bar
                //         data={techEval.data}
                //         options={techEval.options}
                //         height={chartHeight}
                //       />
                //     }
                //   />
                //   <CustomSlide
                //     title="IPC별 특허등급(5개년)"
                //     content={
                //       <Bar
                //         data={ipc.data}
                //         options={ipc.options}
                //         height={chartHeight}
                //       />
                //     }
                //   /> */}
                // </Slider>
              }
            />
            <DBox
              content={
                <ContentLayout
                  title="기술 이전 현황"
                  content={
                    loadingStates.isLoadingRightData ? (
                      <MiniLoading show={loadingStates.isLoadingRightData} />
                    ) : (
                      <>
                        <div className="chart_box auto_width">
                          <Bar
                            data={rightData.chartData}
                            options={techTransfer.options}
                            height={chartHeight}
                          />
                        </div>
                      </>
                    )
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   <CustomSlide
                //     title="기술 이전 현황"
                //     content={
                //       loadingStates.isLoadingRightData ? (
                //         <MiniLoading show={loadingStates.isLoadingRightData} />
                //       ) : (
                //         <>
                //           <div className="chart_box auto_width">
                //             <Bar
                //               data={rightData.chartData}
                //               options={techTransfer.options}
                //               height={chartHeight}
                //             />
                //           </div>
                //         </>
                //       )

                //       // <Bar
                //       //   data={techTransfer.data}
                //       //   options={techTransfer.options}
                //       //   height={chartHeight}
                //       // />
                //     }
                //   />
                //   {/* <CustomSlide
                //     title="기술 이전 현황"
                //     content={
                //       <div className="tech_map">
                //         <Researcher
                //           getInventorList={handleResearcherClick}
                //           setIsPopOpen={setIsPopOpen}
                //           getData={getInventor}
                //         />
                //         <KoreaMap />
                //       </div>
                //     }
                //   />
                //   <CustomSlide
                //     title="기술 이전 현황"
                //     content={
                //       <Pie
                //         data={techTransfer2.data}
                //         options={techTransfer2.options}
                //         height={chartHeight}
                //       />
                //     }
                //   /> */}
                // </Slider>
              }
            />
            <DBox
              content={
                <ContentLayout
                  title="주요 발명자 현황"
                  content={
                    <Researcher
                      getInventorList={handleResearcherClick}
                      setIsPopOpen={setIsPopOpen}
                      getData={getInventor}
                    />
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   <CustomSlide
                //     title="주요 발명자 현황"
                //     content={
                //       <Researcher
                //         getInventorList={handleResearcherClick}
                //         setIsPopOpen={setIsPopOpen}
                //         getData={getInventor}
                //       />
                //     }
                //   />
                //   {/* <CustomSlide
                //     title="발명자별 IPC 매트릭스"
                //     content={
                //       <HeatMap
                //         xLabels={mainCountryByYear.xLabels}
                //         yLabels={mainCountryByYear.yLabels}
                //         data={mainCountryByYear.data}
                //         itemWidth={30}
                //         itemHeight={26}
                //       />
                //     }
                //   /> */}
                // </Slider>
              }
            />
          </div>
          <div className="row row3">
            <DBox
              content={
                <ContentLayout
                  title="예상 연차료 현황"
                  content={
                    loadingStates.isLoadingAnnualPayNextYear ? (
                      <MiniLoading
                        show={loadingStates.isLoadingAnnualPayNextYear}
                      />
                    ) : (
                      <Annuity
                        cost={window.$Global.commaify(annualPayNextYear.cost)}
                        year={annualPayNextYear.year}
                      />
                    )
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   <CustomSlide
                //     title="예상 연차료 현황"
                //     content={
                //       loadingStates.isLoadingAnnualPayNextYear ? (
                //         <MiniLoading
                //           show={loadingStates.isLoadingAnnualPayNextYear}
                //         />
                //       ) : (
                //         <Annuity
                //           cost={window.$Global.commaify(annualPayNextYear.cost)}
                //           year={annualPayNextYear.year}
                //         />
                //       )
                //     }
                //   />
                //   {/* <CustomSlide
                //     title="예상 연차료 현황"
                //     content={<ExpectationAnnual history={history} size="sm" />}
                //   /> */}
                //   {/* <CustomSlide
                //     title="기술 이전 현황"
                //     content={
                //       <Line
                //         data={annualIpc.data}
                //         options={annualIpc.options}
                //         height={chartHeight}
                //       />
                //     }
                //   /> */}
                // </Slider>
              }
            />
            <DBox
              content={
                <ContentLayout
                  title="등록 특허의 연차별 생존율"
                  content={
                    loadingStates.isLoadingAliveRegisterAliveByYear ? (
                      <MiniLoading
                        show={loadingStates.isLoadingAliveRegisterAliveByYear}
                      />
                    ) : (
                      <div className="half_doughnut">
                        {/* <Doughnut
                          data={{
                            labels: ["생존율", ""],
                            datasets: [
                              {
                                data: [aliveRate, 100 - aliveRate],
                                backgroundColor: ["#4e73df", "#dfe5eb"],
                                borderWidth: 0,
                              },
                            ],
                          }}
                          options={percent.options}
                          height={80}
                        /> */}
                        <Bar
                          data={aliveRegisterAliveByYear.chartData}
                          options={techTransfer.options}
                          height={chartHeight}
                        />
                        {/* <div className="txt">
                          <strong>{aliveRate}%</strong>
                          <p>등록특허 생존율</p>
                        </div> */}
                      </div>
                    )
                  }
                  minH={300}
                  borderBtm={false}
                />
                // <Slider {...settings}>
                //   <CustomSlide
                //     title="등록 특허의 연차별 생존율"
                //     content={
                //       loadingStates.isLoadingAliveRegisterAliveByYear ? (
                //         <MiniLoading
                //           show={loadingStates.isLoadingAliveRegisterAliveByYear}
                //         />
                //       ) : (
                //         <div className="half_doughnut">
                //           {/* <Doughnut
                //         data={{
                //           labels: ["생존율", ""],
                //           datasets: [
                //             {
                //               data: [aliveRate, 100 - aliveRate],
                //               backgroundColor: ["#4e73df", "#dfe5eb"],
                //               borderWidth: 0,
                //             },
                //           ],
                //         }}
                //         options={percent.options}
                //         height={80}
                //       /> */}
                //           <Bar
                //             data={aliveRegisterAliveByYear.chartData}
                //             options={techTransfer.options}
                //             height={chartHeight}
                //           />
                //           {/* <div className="txt">
                //         <strong>{aliveRate}%</strong>
                //         <p>등록특허 생존율</p>
                //       </div> */}
                //         </div>
                //       )
                //     }
                //   />
                //   {/* <CustomSlide
                //     title="생존특허 특성별 분포"
                //     content={
                //       <div className="survival">
                //         <div>
                //           <Pie
                //             data={survival.data}
                //             options={survival.options}
                //             height={chartHeight}
                //           />
                //         </div>
                //         <div>
                //           <Pie
                //             data={survivalIpc.data}
                //             options={survivalIpc.options}
                //             height={chartHeight}
                //           />
                //         </div>
                //       </div>
                //     }
                //   />
                //   <CustomSlide
                //     title="특허분석"
                //     content={
                //       <Pie
                //         data={survivalPatent.data}
                //         options={survivalPatent.options}
                //         height={chartHeight}
                //       />
                //     }
                //   /> */}
                // </Slider>
              }
            />
          </div>
          {/* <Bar data={floating.data} options={floating.options} /> */}
          {/* <Line data={doubleLine.data} options={doubleLine.options} /> */}
          <div className="row row4">
            <DBox
              content={
                <DistributionStatus
                  loading={false}
                  defaultData={allApplyData}
                />
              }
            />
          </div>
          <div className="info_slide">
            <p className="warn">
              <span>최근 10개년 특허 ({getYearsAgo(10)}년 1월 1일 ~ 현재)</span>
            </p>
            <DBox
              content={
                <ToggleSlide
                  title="특허 현황"
                  content={
                    <>
                      <ContentLayout
                        title="전체 특허 현황"
                        content={
                          loadingStates.isLoadingAllPatentStatus ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAllPatentStatus}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Treemap
                                  chartData={allPatentStatus.chartData}
                                  label="국내외 특허현황"
                                  colors={colors}
                                  isComma={true}
                                  unit="건수"
                                  onClickHandler={allPatentStatusClick}
                                  cneterTxt={
                                    <strong className="total_cnt">
                                      총
                                      {window.$Global.commaify(
                                        allPatentStatus.totalCnt
                                      )}
                                      건 출원
                                    </strong>
                                  }
                                />
                              </div>
                              <InfoTable
                                data={allPatentStatus.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="국내외 특허현황"
                        content={
                          loadingStates.isLoadingRegionData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingRegionData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <HorizontalBar
                                  data={regionData.chartData}
                                  options={patentStatusRegion.options}
                                  height={chartHeight + 140}
                                  plugins={ChartDataLabels}
                                />
                              </div>
                              <InfoTable
                                data={regionData.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="연도별 IPC현황"
                        content={
                          loadingStates.isLoadingIpcStatus ? (
                            <MiniLoading
                              show={loadingStates.isLoadingIpcStatus}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={ipcStatus.chartData}
                                  options={patentStatusByYear.options2}
                                  height={chartHeight}
                                />
                              </div>
                              <div style={{ flex: "1" }}>
                                <InfoTable
                                  data={ipcStatus.tableData}
                                  units="건수"
                                />
                                <InfoTable data={ipcStatus.ipcDescTableData} />
                              </div>
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="전체 연도별 현황"
                        content={
                          loadingStates.isLoadingAllYearStatus ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAllYearStatus}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={allYearStatus.chartData}
                                  options={allYearStatusOptions}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={allYearStatus.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="해외 연도별 현황"
                        content={
                          loadingStates.isLoadingInterYearStatus ? (
                            <MiniLoading
                              show={loadingStates.isLoadingInterYearStatus}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={interYearStatus.chartData}
                                  options={allYearStatusOptions}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={interYearStatus.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="연도별 국내 누적 현황"
                        content={
                          loadingStates.isLoadingAllCumulativeYearStatus ? (
                            <MiniLoading
                              show={
                                loadingStates.isLoadingAllCumulativeYearStatus
                              }
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={allCumulativeYearStatus.chartData}
                                  options={techEval.options}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={allCumulativeYearStatus.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="연도별 해외 누적 현황"
                        content={
                          loadingStates.isLoadingInterCumulativeYearStatus ? (
                            <MiniLoading
                              show={
                                loadingStates.isLoadingInterCumulativeYearStatus
                              }
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={interCumulativeYearStatus.chartData}
                                  options={techEval.options}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={interCumulativeYearStatus.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                    </>
                  }
                />
              }
            />
          </div>
          <div className="info_slide">
            <DBox
              content={
                <ToggleSlide
                  title="국가별 출원 현황"
                  content={
                    <>
                      <ContentLayout
                        title="주요국 출원 현황"
                        content={
                          loadingStates.isLoadingMainApplyData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingMainApplyData}
                            />
                          ) : (
                            <>
                              <div className="chart_box flex world_box">
                                <div>
                                  <p className="sub_tit">주요국 출원 현황</p>
                                  <div>
                                    <Pie
                                      data={mainApplyData.chartData}
                                      options={country.options}
                                      height={chartHeight}
                                      plugins={ChartDataLabels}
                                    />
                                  </div>
                                </div>
                                <div>
                                  <p className="sub_tit">주요국의 IPC 출원 현황</p>
                                  <div>
                                    <WorldMapChart
                                      colors={colors}
                                      worldData={mainApplyIPCData}
                                    />
                                  </div>
                                </div>
                              </div>
                              <InfoTable
                                data={mainApplyData.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="주요국의 연도별 출원현황"
                        content={
                          loadingStates.isLoadingMainApplyYearData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingMainApplyYearData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={mainApplyYearData?.chartData}
                                  options={techEval.options}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={mainApplyYearData.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="주요국의 연도별 출원현황 매트릭스"
                        content={
                          loadingStates.isLoadingMainApplyMatrixData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingMainApplyMatrixData}
                            />
                          ) : (
                            <>
                              <HeatMap
                                xLabels={
                                  mainApplyMatrixData?.chartData?.xLabels || []
                                }
                                yLabels={
                                  mainApplyMatrixData?.chartData?.yLabels || []
                                }
                                data={
                                  mainApplyMatrixData?.chartData?.data || [[]]
                                }
                                color={colors[0]}
                              />
                            </>
                          )
                        }
                      />
                    </>
                  }
                />
              }
            />
          </div>
          <div className="info_slide">
            <DBox
              content={
                <ToggleSlide
                  title="기술 평가 현황"
                  content={
                    <>
                      <ContentLayout
                        title="기술 평가 현황"
                        content={
                          loadingStates.isLoadingGradeAllData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingGradeAllData}
                            />
                          ) : (
                            <>
                              <div className="chart_box tech_level_chart_box">
                                <div className="tech_level_chart">
                                  <div>
                                    <Doughnut
                                      data={gradeAllData.chartData}
                                      options={techStatus.options}
                                      height={chartHeight}
                                    />
                                  </div>
                                  {/* <div className="avg">
                                    <p>
                                      종합
                                      <br />
                                      특허등급
                                    </p>
                                    <strong>
                                      {gradeAllData.levels["평균 특허 등급"]}
                                    </strong>
                                  </div> */}
                                </div>
                                <div className="levels">
                                  <ul>
                                    <li>
                                      <p>활용성 등급</p>
                                      <strong style={{ background: colors[0] }}>
                                        {
                                          gradeAllData.levels[
                                            "평균 활용성 등급"
                                          ]
                                        }
                                      </strong>
                                    </li>
                                    <li>
                                      <p>권리성 등급</p>
                                      <strong style={{ background: colors[1] }}>
                                        {
                                          gradeAllData.levels[
                                            "평균 권리성 등급"
                                          ]
                                        }
                                      </strong>
                                    </li>
                                    <li>
                                      <p>기술성 등급</p>
                                      <strong style={{ background: colors[2] }}>
                                        {
                                          gradeAllData.levels[
                                            "평균 기술성 등급"
                                          ]
                                        }
                                      </strong>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <InfoTable data={gradeAllData.tableData} />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="연도별 특허등급"
                        // 생존특허의 특허등급 매트릭스
                        content={
                          loadingStates.isLoadingAliveGradeMatrixData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAliveGradeMatrixData}
                            />
                          ) : (
                            <>
                              <div className="chart_box auto_width">
                                <HeatMap
                                  xLabels={
                                    aliveGradeMatrixData?.chartData?.xLabels ||
                                    []
                                  }
                                  yLabels={
                                    aliveGradeMatrixData?.chartData?.yLabels ||
                                    []
                                  }
                                  data={
                                    aliveGradeMatrixData?.chartData?.data || [
                                      [],
                                    ]
                                  }
                                  color={colors[0]}
                                />
                              </div>
                            </>
                          )
                        }
                      />
                      {/* <ContentLayout
                        title="연도별 특허등급"
                        content={
                          loadingStates.isLoadingGradeYearData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingGradeYearData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={gradeYearData.chartData}
                                  options={patentStatusByYear.optionsTech}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable data={gradeYearData.tableData} />
                            </>
                          )
                        }
                        isTable={true}
                      /> */}
                      {/* <ContentLayout
                        title="해외 출원건의 연도별 특허 등급"
                        content={
                          loadingStates.isLoadingGradeYearInterData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingGradeYearInterData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={gradeYearInterData.chartData}
                                  options={patentStatusByYear.optionsTech}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable data={gradeYearInterData.tableData} />
                            </>
                          )
                        }
                        isTable={true}
                      /> */}
                      <ContentLayout
                        title="IPC별 특허 등급"
                        content={
                          loadingStates.isLoadingIpcYearData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingIpcYearData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={ipcYearData.chartData}
                                  options={patentStatusByYear.options2}
                                  height={chartHeight}
                                />
                              </div>
                              <div style={{ flex: "1" }}>
                                <InfoTable
                                  data={ipcYearData.tableData}
                                  units="건수"
                                />
                                <InfoTable
                                  data={ipcYearData.tableData2}
                                  units="점"
                                />
                              </div>
                            </>
                          )
                        }
                        isTable={true}
                      />
                      {/* <ContentLayout
                        title="해외 출원 건의 IPC별 특허 등급"
                        content={
                          loadingStates.isLoadingIpcInterYearData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingIpcInterYearData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={ipcInterYearData.chartData}
                                  options={patentStatusByYear.options2}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={ipcInterYearData.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      /> */}
                    </>
                  }
                  isTable={true}
                />
              }
            />
          </div>
          <div className="info_slide">
            <DBox
              content={
                <ToggleSlide
                  title="기술 이전 현황"
                  content= {
                      <>
                        <ContentLayout
                          title="전체 누적 기술이전 건수"
                          content={
                            loadingStates.isLoadingRightData ? (
                              <MiniLoading 
                                show={loadingStates.isLoadingRightData} 
                              />
                            ): (
                              <>
                                <div className="chart_box">
                                  <Bar
                                    data={rightData.chartData}
                                    options={techTransfer.options}
                                    height={chartHeight}
                                  />
                                </div>
                                <InfoTable
                                  data={rightData.tableData}
                                  units="건수"
                                />
                              </>
                            )
                            
                          }
                          isTable={true}
                        />
                        {/* <ContentLayout
                          title="지역별 누적 기술이전 현황"
                          content={
                            loadingStates.isLoadingKoreaData ? (
                              <MiniLoading
                                show={loadingStates.isLoadingKoreaData}
                              />
                            ) : (
                              <>
                                <div className="korean">
                                  <KoreaMap
                                    data={koreaData.chartData}
                                    plugins={ChartDataLabels}
                                  />
                                </div>
                                <InfoTable
                                  data={koreaData.tableData}
                                  units="건수"
                                />
                              </>
                            )
                          }
                          isTable={true}
                        /> */}
                        <ContentLayout
                          title="국내 기술이전 특허의 주요 IPC 현황"
                          content={
                            loadingStates.isLoadingIpcRightData ? (
                              <MiniLoading
                                show={loadingStates.isLoadingIpcRightData}
                              />
                            ) : (
                              <>
                                <div className="chart_box">
                                  <Pie
                                    data={ipcRightData.chartData}
                                    options={country.options}
                                    height={chartHeight}
                                    plugins={ChartDataLabels}
                                  />
                                </div>
                                <InfoTable
                                  data={ipcRightData.tableData}
                                  units="건수"
                                />
                              </>
                            )
                          }
                          isTable={true}
                        />
                        <ContentLayout
                          title="기술이전된 특허의 현황"
                          content={
                            loadingStates.isLoadingRightAliveData ? (
                              <MiniLoading
                                show={loadingStates.isLoadingRightAliveData}
                              />
                            ) : (
                              <>
                                <div className="double_chart">
                                  <div className="chart_box">
                                    <Bar
                                      data={rightAliveData.barChartData}
                                      options={techTransfer.options}
                                      height={chartHeight}
                                    />
                                  </div>
                                  <div className="chart_box">
                                    <Pie
                                      data={rightAliveData.pieChartData}
                                      options={country.options}
                                      height={chartHeight}
                                      plugins={ChartDataLabels}
                                    />
                                  </div>
                                </div>
                              </>
                            )
                          }
                        />
                        {
                        /*
                        // Reject 컴포넌트
                        <ContentLayout
                          title="연도별 기술이전 ( 양도 ) 수가 많은 대학 top 3"
                          content={
                            <>
                            <div className="double_chart">
                              <div className="chart_box">
                                  <Bar
                                    data={top3Data.chartData}
                                    options={techTransfer.options}
                                    height={chartHeight}
                                  />
                                </div>
                            </div>
                            </>
                          }
                        />
                        */}
                        {/*                         
                        <ContentLayout
                          title="연도별 기술이전(양도)수가 많은 대학&특허"
                          content={
                            <div className="categoryTableWrapper">
                              <ul className="category">
                                {ten_Years.map(year=> (
                                  <li key={year}>
                                    <button 
                                      className={selectYear === year ? 'on' : ''} // 선택된 연도에 따라 className 설정
                                      onClick={(e) => {
                                        e.preventDefault() // 기본 동작 차단
                                        setSelectYear(year) // 연도 설정
                                      }}
                                    >
                                      {year}
                                    </button>
                                  </li>
                                ))}
                              </ul>
                              <div>
                                <table>
                                  <thead>
                                    <tr>
                                      <th>Rank</th>
                                      <th>대학교</th>
                                      <th>건수</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {top10Data.length ? (
                                      top10Data.map((item) => ( // item[0]: Rank, item[1]: 대학교, item[2]: 건수, item[3]: 기업 코드
                                        <tr
                                          key={item[0]}
                                          onClick={() => {
                                            handleOpenModal(item[3]);
                                            setModalName(item[1]);
                                          }}
                                        >
                                          <td>{item[0]}</td>
                                          <td>{item[1]}</td>
                                          <td>{item[2]}</td>
                                        </tr>
                                      ))
                                    ): (
                                      <tr>
                                        <td colSpan="3">데이터가 없습니다.</td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>

                              {isListModal && (
                                <div id="Modal_table">
                                  <strong>{modalName}</strong>
                                  <div className="tbl_box">
                                    <table>
                                      <colgroup>
                                        <col width={20}/>
                                        <col width={120}/>
                                        <col width={160} />
                                        <col width={140} />
                                        <col width={100} />
                                      </colgroup>
                                      <thead>
                                        <tr>
                                          <th>No.</th>
                                          <th>IPC</th>
                                          <th>기술명</th>
                                          <th>발명자</th>
                                          <th>양수인</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {transferDetails.tableData?.length ? (
                                          // item.no: No., item.ipc: IPC, item.invention_name: 기술명, item.inventor: 발명자, item.transferee: 양수인
                                          // item.apply_number: 신청번호, item.nation: 국가, item.lt_number: 해외번호
                                          transferDetails.tableData.map((item) => ( 
                                            <tr key={item.no}
                                              onClick={() => {
                                                setIsDetailModal(true);
                                                setViewDetailModalData({
                                                  nation_string: item.nation || "",
                                                  apply_number: item.apply_number || "",
                                                  lt_number:item.lt_number || "",
                                                })
                                              }}
                                            >
                                              <td>{10 * (transferDetails.page - 1) + item.no}</td>
                                              <td
                                                onMouseEnter={() => {
                                                  if (item.ipc.length > 1) {
                                                    setHovered(true);
                                                    setHoveredField(`ipc-${item.no}`); // 독립적인 호버 키 설정
                                                  }
                                                }}
                                                onMouseLeave={() => {
                                                  setHovered(false);
                                                  setHoveredField("");
                                                }}
                                              >
                                                {ipcSummary(item.ipc)}
                                                {hovered && hoveredField === `ipc-${item.no}` && (
                                                  <div className="popup">{item.ipc.join(", ")}</div>
                                                )}
                                              </td>
                                              <td style={{ textAlign: "left" }}>{item.invention_name}</td>
                                              <td
                                                onMouseEnter={() => {
                                                  if (item.inventor.length > 1) {
                                                    setHovered(true);
                                                    setHoveredField(`inventor-${item.no}`); // 독립적인 호버 키 설정
                                                  }
                                                }}
                                                onMouseLeave={() => {
                                                  setHovered(false);
                                                  setHoveredField("");
                                                }}
                                              >
                                                {inventorSummary(item.inventor)}
                                                {hovered && hoveredField === `inventor-${item.no}` && (
                                                  <div className="popup">{item.inventor.join(", ")}</div>
                                                )}
                                              </td>
                                              <td>{item.transferee}</td>
                                            </tr>
                                          ))
                                        ): (
                                          <tr>
                                            <td colSpan="5">데이터가 없습니다.</td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <Pagination 
                                    curPage={+transferDetails.page}
                                    lastNum={Math.ceil(transferDetails.total_count / +transferDetails.count)}
                                    onClick={handleDetailsPageChagne}
                                    disableMoveToTop={true}
                                  />
                                  <button
                                    className="close"
                                    onClick={()=> {
                                      setIsListModal(false);
                                      setDetailsPage(1);
                                    }}
                                  >
                                    <i className="icon_exit_gray"></i>
                                  </button>
                                </div>
                              )}
                              {isDetailModal && (
                                <div className="modal">
                                  <iframe
                                    src={`https://biznavi.co.kr/researchTech/ipnow/patent/${
                                      viewDetailModalData.nation_string === "KR"
                                        ? viewDetailModalData.apply_number.replace(/-/g, "")
                                        : viewDetailModalData.lt_number
                                    }/detail?nation=${
                                      viewDetailModalData.nation_string
                                    }&companyName=${compName}&tab=0`}
                                    width="100%"
                                    height="100%"
                                    title="특허상세"
                                  />
                                  <button onClick={() => setIsDetailModal(false)}>
                                    <i className="icon_exit" />
                                  </button>
                              </div>
                              )}
                            </div>
                          }
                        /> */}

                        {/* <ContentLayout
                          title="기업 기술이전 분야 네트워크 - IPC"
                          content={
                              <>
                                <div className="graphWrapper">
                                  <NetworkGraph nodes={nearbyOransData} ipcNodes={ipcCompData} name={compName} isModal={isGraphModal} handleModal={handleModalGraphClick}/>
                                  {isGraphModal && (
                                    <>
                                      <div className="graphModal">
                                        <NetworkGraph nodes={modalNearbyOransData} ipcNodes={modalIpcCompData} name={modalGraphName} isModal={isGraphModal} handleModal={handleModalGraphClick} />
                                        <button
                                          className="close"
                                          onClick={()=>setIsGraphModal(false) }
                                        >
                                          <i className="icon_exit_gray"></i>
                                        </button>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                          }
                        /> */}
                      </>
                  }
                />
              }
            />
          </div>
          <div className="info_slide">
            <DBox
              content={
                <ToggleSlide
                  title="주요 발명자 현황"
                  content={
                    <>
                      <ContentLayout
                        title="주요 발명자 현황"
                        content={
                          <Researcher
                            getInventorList={handleResearcherClick}
                            setIsPopOpen={setIsPopOpen}
                            getData={getInventor}
                          />
                        }
                      />
                      <ContentLayout
                        title="발명자별 연도별 출원건수"
                        content={
                          loadingStates.isLoadingInventorData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingInventorData}
                            />
                          ) : (
                            <>
                              <div className="double_chart">
                                <div className="chart_box">
                                  <Line
                                    data={inventorByYear.chartData}
                                    options={patentStatusByYear.options2}
                                    height={chartHeight}
                                  />
                                </div>
                              </div>
                            </>
                          )
                        }
                      />
                      <ContentLayout
                        title="발명자*IPC 매트릭스"
                        content={
                          loadingStates.isLoadingInventorData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingInventorData}
                            />
                          ) : (
                            <>
                              <HeatMap
                                xLabels={
                                  inventorData?.ipcData?.chartData?.xLabels ||
                                  []
                                }
                                yLabels={
                                  inventorData?.ipcData?.chartData?.yLabels ||
                                  []
                                }
                                data={
                                  inventorData?.ipcData?.chartData?.data || [[]]
                                }
                                color={colors[0]}
                                clickEvent={(data) =>
                                  handleClickIpcInvestor(data)
                                }
                              />
                            </>
                          )
                        }
                      />
                      <ContentLayout
                        title="발명자*특허등급 매트릭스"
                        content={
                          loadingStates.isLoadingInventorData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingInventorData}
                            />
                          ) : (
                            <>
                              <HeatMap
                                xLabels={
                                  inventorData?.gradeData?.chartData?.xLabels ||
                                  []
                                }
                                yLabels={
                                  inventorData?.gradeData?.chartData?.yLabels ||
                                  []
                                }
                                data={
                                  inventorData?.gradeData?.chartData?.data || [
                                    [],
                                  ]
                                }
                                color={colors[0]}
                              />
                              <div className="inventor_find">
                                <strong className="layout_tit">
                                  발명자 검색
                                </strong>
                                <div className="sch_box">
                                  <input
                                    type="text"
                                    placeholder="발명자명을 입력해주세요"
                                    onChange={(e) =>
                                      setFindName(e.target.value)
                                    }
                                    value={findName}
                                  />
                                  <button
                                    onClick={() => {
                                      findInventor(findName);
                                    }}
                                  >
                                    <i className="icon_search_blue"></i>
                                  </button>
                                </div>
                              </div>
                            </>
                          )
                        }
                      />
                    </>
                  }
                />
              }
            />
          </div>
          <div className="info_slide">
            <DBox
              content={
                <ToggleSlide
                  title="예상 연차료 현황"
                  content={
                    <>
                      <ContentLayout
                        title=""
                        content={
                          loadingStates.isLoadingAnnualPayNextYear ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAnnualPayNextYear}
                            />
                          ) : (
                            <>
                              <div className="next_annual">
                                <strong className="cost">
                                  {window.$Global.commaify(
                                    annualPayNextYear.cost
                                  )}
                                  원
                                </strong>
                                <p className="year">
                                  {annualPayNextYear.year}년 예상연차료
                                </p>
                              </div>
                            </>
                          )
                        }
                      />
                      <ContentLayout
                        title="연도별 예상연차료"
                        content={
                          loadingStates.isLoadingAnnualPayByYear ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAnnualPayByYear}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={annualPayByYear.chartData}
                                  options={annualOption}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={annualPayByYear.tableData}
                                units="원"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="연도별 예상 연차료 상세"
                        content={
                          loadingStates.isLoadingAnnualPayByYearDetail ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAnnualPayByYear}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={annualPayByYearDetail.chartData}
                                  options={annualOption2}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={annualPayByYearDetail.tableData}
                                units="원"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="IPC별 예상연차료"
                        content={
                          loadingStates.isLoadingAnnualPayByIpc ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAnnualPayByIpc}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={annualPayByIpc.chartData}
                                  options={annualOption2}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={annualPayByIpc.tableData}
                                units="원"
                                tableWidth={1200}
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="기술등급별 차년도 예상 연차료"
                        content={
                          loadingStates.isLoadingAnnualPayByGrade ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAnnualPayByGrade}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={annualPayByGrade.chartData}
                                  options={annualOption}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={annualPayByGrade.tableData}
                                units="원"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      {/* <ContentLayout
                        title="해외출원 예상 연차료"
                        content={
                          loadingStates.isLoadingAnnualPayByGlobal ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAnnualPayByGlobal}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={annualPayByGlobal.chartData}
                                  options={annualOption3}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={annualPayByGlobal.tableData}
                                units="원"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      /> */}
                    </>
                  }
                />
              }
            />
          </div>
          <div className="info_slide">
            <DBox
              content={
                <ToggleSlide
                  title="특허생존률"
                  content={
                    <>
                      <ContentLayout
                        title="연도별 등록특허의 수명(나이)"
                        content={
                          loadingStates.isLoadingAliveAvgData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAliveAvgData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={aliveAvgData.chartData}
                                  options={patentStatusByYear.options3}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={aliveAvgData.tableData}
                                units="개월"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="연도별 등록특허 현황"
                        content={
                          loadingStates.isLoadingRegisterPatentByYear ? (
                            <MiniLoading
                              show={loadingStates.isLoadingRegisterPatentByYear}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={registerPatentByYear.chartData}
                                  options={techEval.options}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={registerPatentByYear.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="등록특허의 유지 비율"
                        content={
                          loadingStates.isLoadingRegisterMaintenanceByYear ? (
                            <MiniLoading
                              show={
                                loadingStates.isLoadingRegisterMaintenanceByYear
                              }
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={registerMaintenanceByYear.chartData}
                                  options={techTransfer.options}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={registerMaintenanceByYear.tableData}
                                units={
                                  registerMaintenanceByYear.chartData.units
                                }
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      {/* <ContentLayout
                        title="등록 특허의 포기(소멸) 현황(연도별)"
                        content={
                          loadingStates.isLoadingRegisterGiveUpData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingRegisterGiveUpData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Bar
                                  data={registerGiveUpData.chartData}
                                  options={techTransfer.options}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={registerGiveUpData.tableData}
                                units="건수"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      />
                      <ContentLayout
                        title="등급별 등록 특허의 수명"
                        content={
                          loadingStates.isLoadingAliveGradeData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAliveGradeData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={aliveGradeData.chartData}
                                  options={patentStatusByYear.noLegend}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={aliveGradeData.tableData}
                                units="개월"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      /> */}
                      <ContentLayout
                        title="생존특허의 IPC 매트릭스"
                        content={
                          loadingStates.isLoadingAliveIPCMatrixData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAliveIPCMatrixData}
                            />
                          ) : (
                            <>
                              <div className="chart_box auto_width">
                                <HeatMap
                                  xLabels={
                                    aliveIPCMatrixData?.chartData?.xLabels || []
                                  }
                                  yLabels={
                                    aliveIPCMatrixData?.chartData?.yLabels || []
                                  }
                                  data={
                                    aliveIPCMatrixData?.chartData?.data || [[]]
                                  }
                                  color={colors[0]}
                                />
                              </div>
                            </>
                          )
                        }
                      />
                      {/* <ContentLayout
                        title="등록/소멸 소요시간"
                        content={
                          loadingStates.isLoadingAliveDurationData ? (
                            <MiniLoading
                              show={loadingStates.isLoadingAliveDurationData}
                            />
                          ) : (
                            <>
                              <div className="chart_box">
                                <Line
                                  data={aliveDurationData.chartData}
                                  options={patentStatusByYear.options3}
                                  height={chartHeight}
                                />
                              </div>
                              <InfoTable
                                data={aliveDurationData.tableData}
                                units="개월"
                              />
                            </>
                          )
                        }
                        isTable={true}
                      /> */}
                    </>
                  }
                />
              }
            />
          </div>
        </div>
      </div>
      {isPopOpen && (
        <DistributionPop
          closePatentPop={closePatentPop}
          patents={patentInfo}
          setPatentPage={handlePatentPageChange}
          dataCondition={staticParams}
          setViewData={setViewData}
          setShowPopup={setShowPopup}
        />
      )}
      {showPopup && (
        <div className="modal">
          <iframe
            src={`https://biznavi.co.kr/researchTech/ipnow/patent/${
              viewData.nation_string === "KR"
                ? viewData.apply_number.replace(/-/g, "")
                : viewData.lt_number
            }/detail?nation=${
              viewData.nation_string
            }&companyName=${compName}&tab=0`}
            width="100%"
            height="100%"
            title="특허상세"
          />
          <button onClick={() => setShowPopup(false)}>
            <i className="icon_exit" />
          </button>
        </div>
      )}
      {/* {isLoading && <Loading />} */}
    </div>
  );
};

export default DDashboard;
