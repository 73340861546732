import React from "react";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { Box, IconButton } from "@mui/material";

const MyAccordion = ({ title, opened, clickFunction, fs = "16px", fw="bold", color='#309BF8', px=3, position, space, icon='' }) => {
    return (
        <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            px={px}
            py={1}
            width={"100%"}
        >
            <Box
                component="p"
                sx={{ fontSize: fs, fontWeight: fw, m: 0, color:color }}
            >
                {title}
            </Box>
            <IconButton
                onClick={clickFunction}
                sx={{
                    backgroundColor: opened ? "transparent" : "black",
                    color: opened ? "inherit" : "white",
                    border: "1px solid black",
                    width: 40,
                    height: 40,
                    borderRadius: "50%",
                    cursor:'pointer',
                    position:position,
                    right:space,
                    visibility:icon,
                    "&:hover": {
                        backgroundColor: opened ? "#f5f5f5" : "rgba(0,0,0,0.8)",
                    },
                }}
            >
                {opened ? <IoIosArrowDown /> : <IoIosArrowUp />}
            </IconButton>
        </Box>
    );
};

export default MyAccordion;
