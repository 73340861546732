
import { Box } from "@material-ui/core";
import { Button, MenuItem, Select } from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import SALES_API from "../../../API/annualPay/sales";
import Modal from "../../common/Modal";
import "../css/sales/AnnualFeeSales.scss";

import transformUniversitiesIncome from "../util/sales/transformUniversitiesIncome";
import AnnualSalesFeeTable from "./annualFee/AnnualSalesFeeTable";

import DepositesAndPackages from "./depositesAndPackages/DepositesAndPackages";

const YEAR = new Date().getFullYear();

const AnnualFeeSales = () => {
  const [activeYear, setActiveYear] = useState(YEAR);
  const [newForm, setNewForm] = useState(true);
  const modalRef = useRef();

  const handleNewForm = (value) => {
    setNewForm(value)
  }

  // Modal Logic
  const [activeModal, setActiveModal] = useState(null);

  const openModalHandler = (modalType) => {
    setActiveModal(modalType);
    modalRef.current.open();
  };
  const closeModalHandler = () => {
    setActiveModal(null);
    modalRef.current.close();
    document.body.style.overflow = "initial";
  };

  // Api Logic
  const [universitiesIncomeState, setuniversitiesIncomeState] = useState({
    state: "LOADING",
    universitiesIncome: null,
    universitiesList: null,
    error: null,
  });
  useEffect(() => {
    setuniversitiesIncomeState({ state: "LOADING" });
    SALES_API.getUniversitiesIncome(activeYear)
      .then((result) => {
        const parsedResponce = transformUniversitiesIncome(result.data);
        const companiesList = result?.data?.data.annualPayDepositAllList.map(({ companyName }) => companyName);
        setuniversitiesIncomeState({
          state: "SUCCESS",
          universitiesIncome: parsedResponce,
          universitiesList: companiesList,
          error: null,
        });
      })
      .catch((err) => {
        setuniversitiesIncomeState({
          state: "ERROR",
          error: { message: err?.response.data?.message ?? "오류가 발생했습니다. 다시 시도해 주세요!!!" },
        });
        console.log(err);
      });
  }, [activeYear]);

  return (
    <>
      <div id="AnnualSalesFee">
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: { xs: "column", md: "row" }, paddingTop: 108 }}>
          <Box sx={{ my: '.5rem', order: '1' }} className="btns-wrapper">
            <div>
              <button onClick={() => { openModalHandler("DEPOSITE"); handleNewForm(true); document.body.style.overflow = "hidden"; }} type="button">
                수금액입력
              </button>
              <button onClick={() => { openModalHandler("PACKAGE"); handleNewForm(true); document.body.style.overflow = "hidden"; }} type="button">
                Package 입력
              </button>
            </div>
          </Box>

          <div>
            <div>
              <Button type="button"
                sx={{
                  display: 'block',
                  backgroundColor: "transparent",
                  padding: '0',
                  width: 'fit-content',
                  fontWeight: 'normal',
                  fontSize: '1.2rem',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: "transparent",
                    border: 'none',
                    outline: 'none'
                  }
                }}
              >매출관리</Button>
              <span>전체 연차료 지급 리스트</span>
              <Select
                sx={{
                  backgroundColor: "rgba(216,216,216,0.4)",
                  padding: '0.5rem 0.7rem',
                  minHeight: '40px',
                  maxHeight: '40px',
                  width: 'auto',
                  marginLeft: '1rem',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  borderRadius: '50px',
                  boxShadow: 'none',
                }}
                onChange={(e) => setActiveYear(e.target.value)}
                value={activeYear}>
                {[YEAR - 1, YEAR, YEAR + 1].map((y) => (
                  <MenuItem key={y} value={y}>
                    {y}년
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>
        </Box>

        {universitiesIncomeState.state === "LOADING" ? (
          <div></div>
        ) : universitiesIncomeState.state === "SUCCESS" ? (
          <>
            {universitiesIncomeState.universitiesIncome.length ? (
              <AnnualSalesFeeTable
                yearFeeds={universitiesIncomeState.universitiesIncome ?? []}
                universityList={universitiesIncomeState.universitiesList ?? []}
                setSelectYear={setActiveYear}
                selectYear={activeYear}
              />
            ) : (
              <div>데이터가 설정되지 않았거나 올해</div>
            )}
          </>
        ) : universitiesIncomeState.state === "ERROR" ? (
          <div>{universitiesIncomeState.error.message}</div>
        ) : null}
      </div>
      <Modal ref={modalRef}>
        {activeModal === "DEPOSITE" ? (
          <DepositesAndPackages newForm={newForm} handleNewForm={handleNewForm} onCloseModal={closeModalHandler} modalType="DEPOSITE" />
        ) : activeModal === "PACKAGE" ? (
          <DepositesAndPackages newForm={newForm} handleNewForm={handleNewForm} onCloseModal={closeModalHandler} modalType="PACKAGE" />
        ) : null}
      </Modal>
    </>
  );
};

export default AnnualFeeSales;
