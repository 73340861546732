import React,{useEffect, useState} from "react";
import { Box, Table, TableHead, TableRow, TableCell, Collapse } from "@mui/material";
import { styled } from "@mui/system";
import SaleFeeds from "./SaleFeeds";
import Arrow from "../ui/arrow/arrow";
import useToggle from "../../../../hooks/toggle-hook";
import FixedDownBox from "./fixed-down-box";
import SALES_API from "../../../../API/annualPay/sales";

const StyledTable = styled(Table)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  border: '1px solid #C7C9CC',
  borderRadius: '4px',
  padding: '0'
}));

// /admin/annual/sales


const AnnualSalesFeeTable = ({ yearFeeds, universityList ,selectYear,setSelectYear}) => {
  const [universityCount, setUniversityCount] = useState('대학교 (0)'); 
  const MONTHS = [universityCount, '합계', "1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];
  useEffect(() => {
    SALES_API.getUniversitiesIncome(selectYear)
      .then((result) => {
        setUniversityCount(`대학교 (${result.data.data.annualPayDepositAllList.length})`);
      })
  }, [selectYear]);
  const SaleRow = (data) => {
    const calculateRowTotal = (row) => row.reduce((total, monthNum) => total, 0);

    let totalSum = 0;
    ["total", ...data].map((monthNum, index) => {
      if (index === 0) {
        totalSum += calculateRowTotal(data);
      } else {
        totalSum += monthNum;
      }
    });

    return totalSum;
  };

  function formatNumberWithCommas(number) {
    return number.toLocaleString();
  }

  function sumArrayAtIndex(index) {
    let grandTotal = 0;
    for (let i = 0; i < yearFeeds.length; i++) {
      grandTotal += SaleRow(yearFeeds[i][index]);
    }
    return formatNumberWithCommas(grandTotal);  
  }

  return (
    <Box style={{position:'relative'}}>
      <Box
        sx={{
          overflowX: 'auto',
          backgroundColor: 'white',
          padding: 4,
        }}
      >
        <StyledTable>
          <TableHead>
            <TableRow
              sx={{
                backgroundColor: '#F7F7F8',
                borderRadius: '30px',
              }}
            >
              {MONTHS.map((month, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: 1,
                    paddingLeft: index === 0 ? 3 : 0,
                    textAlign: index === 0 ? "left" : "center",
                    fontSize: '14px',
                    fontWeight: '600',
                    width: index === 0 ? "224px" : "128px",
                  }}
                >
                  {month}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          {yearFeeds.map((data, feedIndex) => (
            <SaleFeeds key={feedIndex} feedIndex={feedIndex} feedRows={data} univName={universityList[feedIndex]} />
          ))}
        </StyledTable>
      </Box>
      <FixedDownBox
        sumArrayAtIndex={sumArrayAtIndex}
      />
    </Box>
  );
};

export default AnnualSalesFeeTable;