import React, { forwardRef, useLayoutEffect, useState } from "react";
import DatePicker from "react-datepicker";
import ko from "date-fns/locale/ko";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../datepicker/datepicker.scss";
import getYear from "date-fns/getYear";
import getMonth from "date-fns/getMonth";
registerLocale("ko", ko);
setDefaultLocale(ko);

const CustomInput = forwardRef(({ value = "", onClick, className, onChange, onInputChange, placeholderTxt, disabledd }, ref) => {
  const handleInputChange = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    if (inputValue.length > 4 && inputValue.length <= 6) {
      inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`;
    } else if (inputValue.length > 6) {
      inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4, 6)}-${inputValue.slice(6, 8)}`;
    }

    const syntheticEvent = {
      target: {
        value: inputValue,
      },
    };

    if (/^\d{4}-\d{2}-\d{2}$/.test(inputValue)) onInputChange(syntheticEvent);
    onChange(syntheticEvent);
  };

  return (
    <input
      type="text"
      value={value}
      className={className}
      onClick={onClick}
      ref={ref}
      onChange={handleInputChange}
      maxLength={10}
      placeholder={placeholderTxt}
      disabled={disabledd}
    />
  );
});

function DatePickerCustom({ data, name = "", onChangeDatePicker, minDate, maxDate, readOnly, placeholderTxt, disabledd=false }) {
  const [inputValue, setInputValue] = useState(data || "");

  useLayoutEffect(() => setInputValue(data || ""), [data]);

  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setInputValue(new Date(newValue).getTime());

    if (/^\d{4}-\d{2}-\d{2}$/.test(newValue)) {
      onChangeDatePicker(new Date(newValue));
    }
  };
  const setWeekendColor = (date) => {
    return date.getDay() === 0 || date.getDay() === 6 ? "color_red" : "";
  };
  const range = (start, end) => {
    return new Array(end - start).fill().map((d, i) => i + start);
  };
  const years = range(1900, getYear(new Date()) + 20, 1);
  const months = ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"];

  return (
    <DatePicker
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled,
      }) => (
        <div className="date_header">
          <div className="select_boxes">
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => changeYear(value)}
              style={{
                width: 70,
                height: 24,
                margin: 0,
                marginRight: 15,
                padding: 0,
                paddingLeft: 10,
                border: "none",
                zIndex: 100,
              }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[months.indexOf(getMonth(date) + 1 + "월")]}
              onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
              style={{
                width: 60,
                height: 24,
                margin: 0,
                padding: 0,
                paddingLeft: 5,
                border: "none",
              }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>
          <button className="btn_date_next" onClick={increaseMonth} disabled={nextMonthButtonDisabled} />
        </div>
      )}
      selected={inputValue}
      onSelect={(date) => {
        setInputValue(date);
        onChangeDatePicker(date);
      }}
      shouldCloseOnSelect={true}
      customInput={<CustomInput value={inputValue} placeholderTxt={placeholderTxt} disabledd={disabledd} onInputChange={handleInputChange} />}
      dayClassName={(date) => setWeekendColor(date)}
      minDate={minDate}
      maxDate={maxDate}
      name={name}
      readOnly={readOnly}
      dateFormat="yyyy-MM-dd"
    />
  );
}

export default DatePickerCustom;
