import React from "react";
import { Box, Collapse, IconButton } from "@mui/material";
import useToggle from "../../../../hooks/toggle-hook";
import Arrow from "../ui/arrow/arrow";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import "./fixed-down-box.scss";

const FixedDownBox = ({ sumArrayAtIndex }) => {
    const [isopened, setIsOpened] = useToggle(false);
    const [isopenedMenu, setIsOpenedMenu] = useToggle(true);
    const universityData = ["관납료", "실비", "IPN 수수료", "수수료 VAT"];

    return (
        <Box className="fixed-down-box" style={{position:'sticky', bottom:'0'}}>
            <div className="header">
                <h3 className="title">모든 대학의 총계</h3>
                <IconButton
                    onClick={setIsOpened}
                    className={`toggle-button ${isopened ? "opened" : "closed"}`}
                >
                    {isopened ? <IoIosArrowDown /> : <IoIosArrowUp />}
                </IconButton>
            </div>
            <Collapse in={isopened}>
                <div className="content">
                    <div className="left-section">
                        <p>총 합계 매출 : {sumArrayAtIndex(0)}</p>
                        <p className="red-text">총 합계 수금 : {sumArrayAtIndex(7)}</p>
                        <p className="green-text">총 합계 미수금 : {sumArrayAtIndex(8)}</p>
                    </div>
                    <div className="divider"></div>
                    <div className="right-section">
                        <p className="packages">총 합계 Packages : {sumArrayAtIndex(6)}</p>
                        <Box className="sub-toggle" onClick={setIsOpenedMenu}>
                            연차료 납부대행 : {sumArrayAtIndex(1)} <Arrow opened={isopenedMenu} />
                        </Box>
                        <Collapse in={isopenedMenu}>
                            {universityData.map((uni, index) => (
                                <p key={index} className="university-data">
                                    {uni} : {sumArrayAtIndex(index + 2)}
                                </p>
                            ))}
                        </Collapse>
                    </div>
                </div>
            </Collapse>
        </Box>
    );
};

export default FixedDownBox;
