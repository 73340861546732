import React from "react";
import Styles from "./table.module.scss";
import { DeleteIcon, EditIcon } from "./icons";

const Table = ({ headersFields, records = [], onDelete, onEdit, styles={} }) => {

  const mergedStyles = { ...styles };

  return (
    <table style={mergedStyles} className={Styles["table"]}>
      <thead>
        <tr>
          {headersFields.map(({ title, name }) => (
            <th key={name}>{title}</th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {records.map((record, recordIndex) => (
          <tr key={record.idx}>
            {headersFields.map(({ name }, index) => (
              <td key={`${record.idx}-${index}`}>{typeof record[name] === 'number' ? window.$Global.numberWithCommas(record[name]) : record[name]}</td>
            ))}
            <td>
              <div className={Styles["edit-buttons"]}>
                <button
                style={{
                  width:'32px',
                  height:'32px',
                  borderRadius:'6px',
                  backgroundColor:'#40444714',
                  display:'flex',
                  alignItems:'center',
                }}
                onClick={() => onEdit(record.idx)} type="button">{EditIcon()}</button>
                <button
                style={{
                  width:'32px',
                  height:'32px',
                  borderRadius:'6px',
                  backgroundColor:'#E42B2B14',
                  display:'flex',
                  alignItems:'center',
                }}
                onClick={() => onDelete(record.idx)} type="button">{DeleteIcon()}</button>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
