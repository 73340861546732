import React, { useState, useEffect, useRef } from "react";
import SharedStyles from "../../css/sales/shared.module.scss";
import { DEPOSITES_HEADER_FIELDS, PACKAGES_HEADER_FIELDS } from "../../constants/TablesFields";
import DepositeHeader from "./deposites/DepositeHeader";
import PackageHeader from "./packages/PackageHeader";
import DepositeForm from "./deposites/DepositeForm";
import SALES_API from "../../../../API/annualPay/sales";
import ConfirmModal from "../ui/confirm/ConfirmModal";
import Table from "../ui/table/Table";
import filterRecords from "../../util/sales/filterRecords";
import PackageForm from "./packages/PackageForm";

const DepositesAndPackages = ({ newForm, handleNewForm, onCloseModal, modalType }) => {
  const [edit, setEdit] = useState(false)
  const [activeModal, setActiveModal] = useState(null);
  const [recordsState, setRecordsState] = useState({ state: "IDLE", records: [] });
  const [refetch, setRefetch] = useState(false);

  //* Api Logic
  const [universitiesState, setUniversitiesState] = useState({
    state: "LOADING",
    universities: null,
    error: null,
  });

  const [filterValues, setFilterValues] = useState({
    university: { name: "기관 선택", trueValue: false },
    year: { name: "년", trueValue: false },
    month: { name: "월", trueValue: false },
  });
  const fetchedCompanyAndYear = useRef({ company_idx: "", year: 0 });

  const handleRefetch = ()=>{
    setRefetch(!refetch)
  }

  useEffect(() => {
    SALES_API.getUniversities()
      .then((result) => {
        setUniversitiesState({ state: "SUCCESS", universities: result.data.data.userList, error: null });
      })
      .catch((err) => setUniversitiesState({ state: "ERROR", universities: null, error: err?.response.data?.message ?? err.message }));
  }, []);

  useEffect(() => {
    if (!filterValues.year.trueValue) return;
    const {
      university: { company_idx },
      year: { name: yearName },
    } = filterValues;
    const yearNumber = +yearName.replace("년", "");
    if (yearNumber === fetchedCompanyAndYear.current.year && company_idx === fetchedCompanyAndYear.current.company_idx)
      return;

    setRecordsState({ state: "LOADING", records: [] });
    fetchedCompanyAndYear.current = { company_idx, year: yearNumber };

    const recordsApi = modalType === "DEPOSITE" ? SALES_API.getDeposites : SALES_API.getPackages;
    recordsApi(company_idx, { year: yearNumber })
      .then((result) => {
        const { sum, depositList } = result.data.data;
        setRecordsState({
          sum,
          records: depositList,
          state: "SUCCESS",
        });
      })
      .catch((err) => {
        console.log(err);
        setRecordsState({
          state: "ERROR",
          message: err?.message ?? "오류가 발생했습니다. 다시 시도해 주세요.",
          records: [],
        });
      });
  }, [filterValues, modalType, refetch]);

  //* The record that will be Edited OR Deleted
  const [recordToWorkOn, setRecordToWorkOn] = useState(null);


  const updateFilterHandler = (name, option) =>
    setFilterValues((prevState) => ({ ...prevState, [name]: { ...option, trueValue: true } }));

  //* You will get a report after the request is made
  const submitHandler = (report) => {
    if (report.status === "SUCCESS") {
      setActiveModal({ type: "SUCCESS", message: report.message });
    } else if (report.status === "ERROR") {
      setActiveModal({ type: "ERROR", message: report.message });
    }

    setFilterValues({ ...filterValues });
    setRecordToWorkOn(null)
    fetchedCompanyAndYear.current.year = 0;
  };

  const deleteRecordHandler = async () => {
    const deleteApi = modalType === "DEPOSITE" ? SALES_API.deleteDeposit : SALES_API.deletePackage;
    const result = await deleteApi({
      idx: recordToWorkOn,
    });
    submitHandler({ status: "SUCCESS", type: "DELETE", message: result.data.message });
  };

  const selectedMonth = filterValues.month.trueValue ? +filterValues.month.name.replace("월", "") : "";
  const filterdRecords = filterRecords({ records: recordsState.records, selectedMonth, type: modalType });

  const totalCost = modalType === "PACKAGE" ? filterdRecords.reduce((sum, { fee }) => (sum += fee), 0) : "";

  const recordOperationHandler = (type, recordId) => {
    switch (type) {
      case "CONFIRM_DELETE":
        setActiveModal({ type: "CONFIRM_DELETE" });
        setRecordToWorkOn(recordId);
        break;
      case "NEW":
        setActiveModal({ type: "FORM" });
        setRecordToWorkOn(null);
        break;
      case "EDIT":
        setEdit(true)
        setActiveModal({ type: "FORM" });
        setRecordToWorkOn(filterdRecords.find(({ idx }) => idx === recordId));
        break;
      default:
        break;
    }
  };

  const backHandler = () => {
    setEdit(false)
    setRecordToWorkOn(null);
  }

  useEffect(() => {
    if (newForm) {
      setEdit(false)
      setRecordToWorkOn(null);
      setActiveModal(null);
      // setFilterValues({
      //   university: { name: "기관 선택", trueValue: false },
      //   year: { name: "년", trueValue: false },
      //   month: { name: "월", trueValue: false },
      // })
      handleNewForm(false)
    }
  }, [newForm])

  useEffect(()=>{
    if(activeModal?.type === "SUCCESS" || activeModal?.type === "ERROR"){
      backHandler()
    }
  },[activeModal?.type])

  // Computed Values

  return (
    <div className={`${SharedStyles["main-modal"]}`}>
      <h2 style={{
        textAlign: "center",
        fontSize: '24px',
        fontWeight: '600',
        margin: '5px auto 15px',
        display: edit ? 'none' : ''
      }}>{modalType === "DEPOSITE" ? '수금액 추가하기' : 'Package 추가하기'}</h2>
      {universitiesState.state === "ERROR" ? (
        <ConfirmModal type="ERROR" onCancel={onCloseModal} />
      ) : (
        <>
          <button className={SharedStyles[(edit && recordToWorkOn) ? "back-btn" : "close-btn"]} onClick={(edit && recordToWorkOn) ? backHandler : onCloseModal} type="button">
            {(edit && recordToWorkOn) ?
              <svg width="32" height="16" viewBox="0 0 32 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.66667 14.6668L1 8.00016M1 8.00016L7.66667 1.3335M1 8.00016L31 8.00016" stroke="#19191A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
              </svg>
              :
              <i className="icon_exit" />
            }
          </button>
          {modalType === "DEPOSITE" ? (
            <DepositeHeader
              depositesState={recordsState}
              universitiesState={universitiesState}
              updateFilterHandler={updateFilterHandler}
              filterValues={filterValues}
              onAdd={() => recordOperationHandler("NEW")}
              displayy={(edit && recordToWorkOn)}
            />
          ) : (
            <PackageHeader
              universitiesState={universitiesState}
              updateFilterHandler={updateFilterHandler}
              filterValues={filterValues}
              totalCost={totalCost}
              onAdd={() => recordOperationHandler("NEW")}
              displayy={(edit && recordToWorkOn)}
            />
          )}
          {(activeModal?.type === "FORM" || activeModal?.type === "SUCCESS" || activeModal?.type === "ERROR" || activeModal == null) && (
            <>
              {activeModal?.type === "CONFIRM_DELETE" ? (
                <ConfirmModal type="CONFIRM_DELETE" onConfirm={deleteRecordHandler} onCancel={() => { setActiveModal(null); backHandler() }} />
              ) : activeModal?.type === "SUCCESS" ? (
                <ConfirmModal type="SUCCESS" onConfirm={() => setActiveModal(null)} message={activeModal.message} />
              ) : activeModal?.type === "ERROR" && (
                <ConfirmModal type="ERROR" onCancel={() => setActiveModal(null)} message={activeModal.message} />
              )
              }
              <div className={SharedStyles["loading-div"]}
                style={{
                  backgroundColor: '#ABABAB',
                  width: '90%',
                  height: '1px',
                  margin: '10px auto 5px',
                  display: (edit && recordToWorkOn) ? 'none' : ''
                }}
              ></div>
              {(edit && recordToWorkOn) &&
                <div style={{ marginTop: '30px', marginBottom: '30px', textAlign: 'center' }}>
                  <h2 style={{ fontSize: '24px', fontWeight: '600', color: '#19191A', marginBottom: '20px' }}>{modalType === "DEPOSITE" ? '수금액 수정하기' : 'Package 수정하기'}</h2>
                  <p style={{ fontSize: '18px', fontWeight: '600', color: '#309BF8' }}>{modalType === "DEPOSITE" ? '한양대학교 에리카' : '한양대학교 에리카'}</p>
                </div>
              }
              {modalType === "DEPOSITE" ? (
                <DepositeForm
                  disabledd={!(filterValues.university.company_idx)}
                  initialValues={
                    recordToWorkOn
                      ? {
                        ...recordToWorkOn,
                        occurrenceDate: new Date(recordToWorkOn.occurrence_date).getTime(),
                        collectionDate: new Date(recordToWorkOn.collection_date).getTime(),
                        depositAmount: recordToWorkOn.deposit_amount,
                      }
                      : {}
                  }
                  university={filterValues.university}
                  onSubmit={submitHandler}
                  onClose={() => setActiveModal(null)}
                  handleRefetch={handleRefetch}
                />
              ) : (
                <PackageForm
                  initialValues={
                    recordToWorkOn
                      ? {
                        ...recordToWorkOn,
                        packageDate: new Date(`${recordToWorkOn.year}-${recordToWorkOn.month}`).getTime(),
                      }
                      : {}
                  }
                  university={filterValues.university}
                  onSubmit={submitHandler}
                  onClose={() => setActiveModal(null)}
                />
              )}
            </>)}
          <div>
            {universitiesState.state === "LOADING" || recordsState.state === "LOADING" ? (
              <div className={SharedStyles["loading-div"]}>
                <span className="spinner" />
              </div>
            ) : (recordsState.state === "IDLE") ? (
              null
            ) : recordsState.state === "ERROR" ? (
              <div style={{ color: "red", fontSize: "1.4rem" }} className={SharedStyles["loading-div"]}>
                {recordsState.message}
              </div>
            ) : (
              <>
                {(!edit) &&
                  <h2 style={{
                    textAlign: "left",
                    fontSize: '18px',
                    fontWeight: '600',
                    margin: '5px auto 15px'
                  }}>{modalType === "DEPOSITE" ? 'Deposits list' : 'Packages list'}</h2>
                }
                <Table
                  styles={{
                    border: '1px solid #C7C9CC', borderRadius: '5px', display: (edit && recordToWorkOn) ? 'none' : ''
                  }}
                  records={filterdRecords?.map((record, index) =>
                    modalType === "DEPOSITE"
                      ? {
                        ...record,
                        no: ++index,
                      }
                      : {
                        ...record,
                        no: ++index,
                        university: filterValues.university.name,
                        packageDate: `${record.year}년 ${record.month}월`,
                      }
                  )}
                  onDelete={(id) => recordOperationHandler("CONFIRM_DELETE", id)}
                  onEdit={(id) => recordOperationHandler("EDIT", id)}
                  headersFields={modalType === "DEPOSITE" ? DEPOSITES_HEADER_FIELDS : PACKAGES_HEADER_FIELDS}
                />
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DepositesAndPackages;
