import { useState } from "react"

const useToggle = (intialstate = true) => {

    const [isOpened, setIsOpened] = useState(intialstate)

    const toggle = () => {
        setIsOpened(prev => !prev);
    };

    return [isOpened, toggle]
}

export default useToggle