import React, { cloneElement, useContext } from "react";
import { Link } from "react-router-dom";
import PatentContext from "../../../store/Patent";
import Modal from "../../common/Modal";
import ToggleButtonWithSpan from "../../common/ToggleButtonWithSpan";
import useReportModal from "../hooks/useReportModal";
import CheckpointReport from "./checkpoint/report/CheckpointReport";
import ButtonApprovalRow from "./common/ButtonApprovalRow";
import ButtonRow from "./common/ButtonRow";
import ClaimCountRow from "./common/ClaimCountRow";
import DateAndFileRow from "./common/DateAndFileRow";
import DatePickerRow from "./common/DatePickerRow";
import DivideApplyRow from "./common/DivideApplyRow";
import InnerDatePickerAndFileRow from "./common/InnerDatePickerAndFileRow";
import InputAndFileRow from "./common/InputAndFileRow";
import InputRow from "./common/InputRow";
import InventorInputRow from "./common/InventorInputRow";
import InventorRow from "./common/InventorRow";
import NormalRow from "./common/NormalRow";
import ProcessManage from "./common/ProcessManage";
import ProcessStep from "./common/ProcessStep";
import ProductAndStateRowContainer from "./common/ProductAndStateRowContainer";
import SelectRow from "./common/SelectRow";
import ShareRow from "./common/ShareRow";
import typeName from "./constrant/typeName";
import FormSelfCorrecting from "./FormSelfCorrecting";

function FormCompanyKr({
  productSelect,
  jointApplyListTag,
  jointRightHolderListTag,
  inventorSelect,
  inventorSelectTag,
  agentCompanyListTag,
  ListOaTag,
  inventorListTag,
  managerSelectTag,
  checkAuth,
  onClickDownPopShow,
  onClickUpPopShow,
  onChangeInput,
  onClickClaim,
  onClickFirstClaim,
  onClickInventorAdd,
  onClickProductAdd,
  onClickProductDelete,
  onChangeDatePickerInnerDate,
  onChangeClaimCount,
  onChangeDatePicker,
  onChangeInventorInput,
}) {
  const patentInfo = useContext(PatentContext);
  const { reportState, reportModalRef, activePoints, openReportModal, closeReportModal, isCheckpointsExists } =
    useReportModal();

  return (
    <div className="form">
      <Modal ref={reportModalRef}>
        <CheckpointReport onClose={closeReportModal} activePoints={activePoints} reportState={reportState} />
      </Modal>
      <div className="field">
        {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>서지사항 Check</span>
          </button>
        )}
        <ProcessStep text="서지 사항" state={patentInfo.state} />
        <div className="rows">
          <InputRow
            title="관리번호"
            titleClass="color_blue"
            condition={patentInfo.editing}
            content={patentInfo.manage_number}
            onChange={onChangeInput}
            setKey="manage_number"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "관리번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "관리번호" })}
          />
          <InputRow
            title="고객관리번호"
            condition={patentInfo.editing}
            content={patentInfo.our_manage_number}
            onChange={onChangeInput}
            setKey="our_manage_number"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "고객관리번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "고객관리번호" })}
          />
          <NormalRow
            title="유형"
            content={patentInfo.type}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "유형" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "유형" })}
          />
        </div>
        <div className="rows">
          <div className="row">
            <h2 className="title">
              Family 출원
              <Link className="icon_plus" to={`/patent/family/${patentInfo.idx}`} />
              {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "Family 출원" }) && (
                <i
                  onClick={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "Family 출원" })}
                  className="icon_check_empty"
                />
              )}
            </h2>
            <p>{patentInfo.family_cnt}건</p>
          </div>
        </div>
        <div className="rows">
          <SelectRow
            title="관리담당자"
            content={patentInfo.manager_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                manager_idx: e.target.value,
                manager_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            conditions={patentInfo.editing}
            contentIdx={patentInfo.manager_idx}
            selectTag={managerSelectTag}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "관리담당자" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "관리담당자" })}
          />
          <InputRow
            title="실무담당자"
            condition={patentInfo.editing}
            content={patentInfo.ip_member_name}
            onChange={onChangeInput}
            setKey="ip_member_name"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "실무담당자" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "실무담당자" })}
          />
          <SelectRow
            title="대리인"
            content={patentInfo.agent_company_name}
            onChange={(e) =>
              patentInfo.setPatent({
                ...patentInfo,
                agent_company_idx: e.target.value,
                agent_company_name: e.target.options[e.target.selectedIndex].text,
              })
            }
            conditions={patentInfo.editing}
            contentIdx={patentInfo.agent_company_idx}
            selectTag={agentCompanyListTag}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "대리인" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "대리인" })}
          />
        </div>
        <div className="rows">
          <InventorRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
            inventorSelect={inventorSelect}
            inventorSelectTag={inventorSelectTag}
            onClickEvent={onClickInventorAdd}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: `발명자 1` })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: `발명자 1` })}
          />
          <ShareRow
            index={0}
            editing={patentInfo.editing}
            inventor={patentInfo.inventor[0]}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "지분율" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "지분율" })}
          />
          <InventorInputRow
            title="이메일"
            condition={patentInfo.editing}
            content={patentInfo.inventor[0].email}
            onChange={onChangeInventorInput}
            index={0}
            setKey="email"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "이메일" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "이메일" })}
          />
        </div>
        <div className="rows" style={{ justifyContent: "flex-end" }}>
          <InventorInputRow
            title="생년월일"
            condition={patentInfo.editing}
            content={patentInfo.inventor[0].birth_date}
            onChange={onChangeInventorInput}
            index={0}
            setKey="birth_date"
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "생년월일" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "생년월일" })}
          />
        </div>
        {inventorListTag}
        <div className="rows">
          <DateAndFileRow
            title="발명신고서 접수"
            date={patentInfo.invention_created_at}
            haveFile={Boolean(patentInfo.invention_file_cnt)}
            onClickDownPopShow={() => onClickDownPopShow(typeName.invent, { description: 1 })}
            onClickUpPopShow={() => onClickUpPopShow(typeName.invent, { description: 1 })}
            uploadBtnShow={checkAuth("특허회사_관리자")}
            withCheckpoint={isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "발명신고서 접수" })}
            onOpenReport={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "발명신고서 접수" })}
          />
          <div className="row">
            <h2 className="title">
              선행기술 조사
              {isCheckpointsExists({ mainCategory: "서지사항 Check", subCategory: "선행기술 조사" }) && (
                <i onClick={() => openReportModal({ mainCategory: "서지사항 Check", subCategory: "선행기술 조사" })} className="icon_check_empty" />
              )}
              <i className="icon_info" data-content={window.$Global.getInfoMessage("선행기술 조사")} />
            </h2>
            <div className="btns prior">
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.survey_req}
                toggleSelected={() =>
                  patentInfo.setPatent({
                    ...patentInfo,
                    survey_req: !patentInfo.survey_req,
                  })
                }
              />
              {!patentInfo.editing && (
                <>
                  <button
                    className={`${Boolean(patentInfo.survey_req) ? "" : "no_click"} ${patentInfo.survey_file_cnt ? "icon_download_attached" : "icon_download"
                      }`}
                    onClick={() => onClickDownPopShow(typeName.survey)}
                  />
                  <button
                    className={`${Boolean(patentInfo.survey_req) ? "" : "no_click"} icon_upload`}
                    onClick={() => onClickUpPopShow(typeName.survey)}
                  />
                </>
              )}
            </div>
          </div>
        </div>
        <div className="rows">
          <ProductAndStateRowContainer
            index={0}
            patentInfo={patentInfo}
            setPatentInfo={patentInfo.setPatent}
            productSelect={productSelect}
            onClickProductAdd={onClickProductAdd}
            onClickProductDelete={onClickProductDelete}
            isCheckpointsExists={isCheckpointsExists}
            onOpenReport={({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory })}
          />
        </div>
        {patentInfo.productListTag && !!patentInfo.productListTag.filter((component) => !!component).length && (
          <>
            {patentInfo.productListTag &&
              patentInfo.productListTag
                .filter((component) => !!component)
                .map((component) => (
                  <>
                    <div className="rows">
                      {cloneElement(component, {
                        onOpenReport: ({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory }),
                        isCheckpointsExists,
                      })}
                    </div>
                  </>
                ))}
          </>
        )}
        {jointApplyListTag &&
          jointApplyListTag.map((component) =>
            cloneElement(component, {
              onOpenReport: ({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory }),
              isCheckpointsExists,
            })
          )}
        {jointRightHolderListTag}
      </div>
      <div className="field">
        {isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "출원 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>출원 Check</span>
          </button>
        )}
        <ProcessStep text="출원" state={patentInfo.state} />
        <div className="rows">
          <InputAndFileRow
            title="출원번호"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "출원번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "출원번호" })}
            titleClassName="color_blue"
            content={patentInfo.apply_number}
            onChange={(e) => onChangeInput("apply_number", e.target.value)}
            editing={patentInfo.editing}
            onClickDownPopShow={() => onClickDownPopShow(typeName.apply)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.apply)}
            haveFile={Boolean(patentInfo.apply_file_cnt)}
          />
          <DatePickerRow
            title="출원일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "출원일" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "출원일" })}
            condition={patentInfo.editing}
            data={patentInfo.apply_at}
            dataKey="apply_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <NormalRow
            title="국내 우선권 주장 기한"
            titleClassName="color_blue"
            content={patentInfo.priority_claim_deadline_domestic}
            contentClassName="color_red"
            txtType="date"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "국내 우선권 주장 기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "국내 우선권 주장 기한" })}
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <NormalRow
            title="우선일"
            content={patentInfo.priority_at}
            txtType="date"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "우선일" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "우선일" })}
          />
          <NormalRow
            title="해외 우선권 주장 기한"
            titleClassName="color_blue"
            content={patentInfo.priority_claim_deadline_foreign}
            contentClassName="color_red"
            txtType="date"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "해외 우선권 주장 기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "해외 우선권 주장 기한" })}
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="발명의 명칭"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "발명의 명칭" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "발명의 명칭" })}
            condition={patentInfo.editing}
            content={patentInfo.invention_name}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name"
          />
          <NormalRow
            title="PCT 출원 기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "PCT 출원 기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "PCT 출원 기한" })}
            titleClassName="color_blue"
            content={patentInfo.pct_apply_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <InputRow
            rowClassName="colspan2"
            title="영문 명칭"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "영문 명칭" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "영문 명칭" })}
            condition={patentInfo.editing}
            content={patentInfo.invention_name_en}
            inputClassName="long"
            onChange={onChangeInput}
            setKey="invention_name_en"
          />
          <div className="row no_data" />
        </div>
        <div className="rows">
          <NormalRow
            title="우선심사청구"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "우선심사청구" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "우선심사청구" })}
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.priority_screen}
                toggleSelected={onClickFirstClaim}
              />
            }
          />
          <NormalRow
            title="심사청구"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "심사청구" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "심사청구" })}
            component={
              <ToggleButtonWithSpan
                className={patentInfo.editingClass}
                text=""
                condition={{ on: "유", off: "무" }}
                selected={patentInfo.screen_req}
                toggleSelected={onClickClaim}
              />
            }
          />
          <NormalRow
            title="심사청구 기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "심사청구 기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "심사청구 기한" })}
            titleClassName="color_blue"
            content={patentInfo.screen_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <DatePickerRow
            title="심사청구일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "심사청구일" })}
            onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "심사청구일" })}
            condition={patentInfo.editing}
            data={patentInfo.screen_req_at}
            dataKey="screen_req_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="process_manage">
          <ProcessManage title="출원 상세 절차 관리" selected={patentInfo.apply_detail} />
          {Boolean(patentInfo.apply_detail) && (
            <>
              <div className="rows">
                <ButtonRow
                  title="초안작성 지시"
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안작성 지시하기" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안작성 지시하기" })}
                  condition={patentInfo.editing}
                  data={patentInfo.draft_order_at}
                  onClick={() =>
                    patentInfo.setPatent({
                      ...patentInfo,
                      draft_order_at: new Date().getTime(),
                    })
                  }
                />
                <InnerDatePickerAndFileRow
                  title="초안 접수"
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안 접수일" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안 접수일" })}
                  data={patentInfo.draftReceivingDate}
                  condition={patentInfo.editing}
                  setKey="draftReceivingDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_file_cnt)}
                  dateBtnHide={true}
                  uploadBtnShow={true}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft, { modified: 0 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft, { modified: 0 })}
                />
                <InnerDatePickerAndFileRow
                  title={`초안\n발명자 검토`}
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안\n발명자 검토" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안\n발명자 검토" })}
                  data={patentInfo.draftInventorsDate}
                  condition={patentInfo.editing}
                  setKey="draftInventorsDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_reply_researcher_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() =>
                    onClickDownPopShow(typeName.draft_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                  onClickUpPopShow={() =>
                    onClickUpPopShow(typeName.draft_reply, {
                      type: 2,
                      modified: 0,
                    })
                  }
                />
              </div>
              <div className="rows">
                <div className="row no_data" />
                <div className="row no_data" />
                <InnerDatePickerAndFileRow
                  title={"초안\n담당자 검토"}
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안\n담당자 검토" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안\n담당자 검토" })}
                  data={patentInfo.draftManagerDate}
                  condition={patentInfo.editing}
                  setKey="draftManagerDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  haveFile={Boolean(patentInfo.draft_reply_manager_file_cnt)}
                  uploadBtnShow={true}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft_reply, { type: 1 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft_reply, { type: 1 })}
                />
              </div>
              <div className="rows">
                <DateAndFileRow
                  title="초안 수정본 전달"
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안 수정본 전달" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안 수정본 전달" })}
                  date={patentInfo.draftModifiedUploadDate.date}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft, { modified: 1 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft, { modified: 1 })}
                  uploadBtnShow={checkAuth("특허회사_관리자")}
                  haveFile={Boolean(patentInfo.modified_draft_file_cnt)}
                />
                <InnerDatePickerAndFileRow
                  title={"초안\n최종본 접수"}
                  withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "초안\n최종본 접수" })}
                  onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "초안\n최종본 접수" })}
                  data={patentInfo.draftFinalDate}
                  condition={patentInfo.editing}
                  setKey="draftFinalDate"
                  onChangeDatePickerJson={onChangeDatePickerInnerDate}
                  uploadBtnShow={true}
                  haveFile={Boolean(patentInfo.final_draft_file_cnt)}
                  onClickDownPopShow={() => onClickDownPopShow(typeName.draft, { modified: 2 })}
                  onClickUpPopShow={() => onClickUpPopShow(typeName.draft, { modified: 2 })}
                />
                {patentInfo.apply_approval ? (
                  <ButtonApprovalRow
                    patentInfo={patentInfo}
                    onClick={() => patentInfo.setPatentApprovalApplyPopupShow(true)}
                  />
                ) : (
                  <ButtonRow
                    title="출원 지시"
                    withCheckpoint={isCheckpointsExists({ mainCategory: "출원 Check", subCategory: "출원 지시하기" })}
                    onOpenReport={() => openReportModal({ mainCategory: "출원 Check", subCategory: "출원 지시하기" })}
                    titleClassName="color_blue"
                    condition={patentInfo.editing}
                    data={patentInfo.apply_order_at}
                    onClick={() =>
                      patentInfo.setPatent({
                        ...patentInfo,
                        apply_order_at: new Date().getTime(),
                      })
                    }
                  />
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {ListOaTag &&
        ListOaTag.map((component, index) =>
          cloneElement(component, {
            key: component.key || index,
            onOpenReport: ({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory }),
            isCheckpointsExists,
          })
        )}
      <FormSelfCorrecting
        onClickDownPopShow={onClickDownPopShow}
        onClickUpPopShow={onClickUpPopShow}
        onChangeDatePicker={onChangeDatePicker}
        onOpenReport={({ mainCategory, subCategory }) => openReportModal({ mainCategory, subCategory })}
        isCheckpointsExists={isCheckpointsExists}
      />
      <div className="field">
        {isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "" }) && (
          <button
            onClick={() => openReportModal({ mainCategory: "등록 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>등록 Check</span>
          </button>
        )}
        <ProcessStep text="등록" state={patentInfo.state} />
        <div className="rows">
          <DatePickerRow
            title="등록 결정일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "등록 결정일" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "등록 결정일" })}
            titleClassName="color_blue"
            condition={patentInfo.editing}
            data={patentInfo.register_decision_at}
            dataKey="register_decision_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
          <InputAndFileRow
            title="등록번호"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "등록번호" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "등록번호" })}
            content={patentInfo.register_number}
            editing={patentInfo.editing}
            haveFile={Boolean(patentInfo.register_file_cnt)}
            onChange={(e) => onChangeInput("register_number", e.target.value)}
            onClickDownPopShow={() => onClickDownPopShow(typeName.register)}
            onClickUpPopShow={() => onClickUpPopShow(typeName.register)}
          />
          <DatePickerRow
            title="등록일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "등록일" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "등록일" })}
            condition={patentInfo.editing}
            data={patentInfo.register_at}
            dataKey="register_at"
            useOaFunc={false}
            onChangeDatePicker={onChangeDatePicker}
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <NormalRow
            title="존속기간 만료일"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "존속기간 만료일" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "존속기간 만료일" })}
            titleClassName="color_blue"
            content={patentInfo.expiration_at}
            contentClassName="color_blue"
            txtType="date"
          />
          <NormalRow
            title="등록기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "등록기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "등록기한" })}
            titleClassName="color_blue"
            content={patentInfo.register_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <div className="row no_data" />
          <div className="row no_data" />
          <NormalRow
            title="관납료 납부기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "관납료 납부기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "관납료 납부기한" })}
            titleClassName="color_blue"
            content={patentInfo.payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
        <div className="rows">
          <DivideApplyRow
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "분할 출원" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "분할 출원" })}
            editingClass={patentInfo.editingClass}
            divideApplication={patentInfo.divideApplication}
            toggleSelected={() =>
              patentInfo.setPatent({
                ...patentInfo,
                divideApplication: {
                  ...patentInfo.divideApplication,
                  value: !patentInfo.divideApplication.value,
                },
              })
            }
          />
          <div className="row no_data" />
          <NormalRow
            title="분할 출원기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "등록 Check", subCategory: "분할 출원기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "등록 Check", subCategory: "분할 출원기한" })}
            titleClassName="color_blue"
            content={patentInfo.division_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
      <div className="field">
        {isCheckpointsExists({ mainCategory: "연차료 정보 Check", subCategory: "" }) && (
          <button
            style={{ top: "55px", left: "-11px" }}
            onClick={() => openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "" })}
            className="checkpoint-btn"
          >
            <i className="icon_check_circle" />
            <span>연차료 정보 Check</span>
          </button>
        )}
        <ProcessStep text="연차료 정보" state={patentInfo.state} />
        <div className="rows">
          <NormalRow
            title="납부년차"
            withCheckpoint={isCheckpointsExists({ mainCategory: "연차료 정보 Check", subCategory: "납부년차" })}
            onOpenReport={() => openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "납부년차" })}
            titleClassName="color_blue"
            content={patentInfo.payment_year && patentInfo.payment_year + "년차"}
            contentClassName="color_blue"
          />
          <ClaimCountRow
            withCheckpoint={isCheckpointsExists({
              mainCategory: "연차료 정보 Check",
              subCategory: "청구항 수(독립항/종속항)",
            })}
            onOpenReport={() =>
              openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "청구항 수(독립항/종속항)" })
            }
            editing={patentInfo.editing}
            claimCount={patentInfo.claimCount}
            onChangeIndependence={(e) => onChangeClaimCount("independence", e.target.value)}
            onChangeDependent={(e) => onChangeClaimCount("dependent", e.target.value)}
          />
          <NormalRow
            title="연차료 기한"
            withCheckpoint={isCheckpointsExists({ mainCategory: "연차료 정보 Check", subCategory: "연차료 기한" })}
            onOpenReport={() => openReportModal({ mainCategory: "연차료 정보 Check", subCategory: "연차료 기한" })}
            titleClassName="color_blue"
            content={patentInfo.annual_payment_deadline}
            contentClassName="color_red"
            txtType="date"
          />
        </div>
      </div>
    </div>
  );
}

export default FormCompanyKr;
