import React, { useEffect, useState } from "react";
import DatePickerCustom from "../../../../datepicker/DatePickerCustom";
import SALES_API from "../../../../../API/annualPay/sales";
import SharedStyles from "../../../css/sales/shared.module.scss";
import useToggle from "../../../../../hooks/toggle-hook";
import MyAccordion from '../../ui/accordion/my-accordion'

const PackageForm = ({ initialValues, onSubmit, onClose, university }) => {
  const [formData, setFormData] = useState(initialValues ?? {});
  const [loading, setloading] = useState(false);
  const [isopened, setIsOpened] = useToggle(true);

  const submitHandler = async (event) => {
    event.preventDefault();
    setloading(true);

    let result;
    const payload = {
      company_idx: university.company_idx,
      packageDate: window.$Global.convertDate(formData.packageDate).substring(0, 7),
      fee: formData.fee,
    };

    try {
      result = !initialValues.idx
        ? await SALES_API.uploadNewPackage(payload)
        : await SALES_API.updatePackage({ ...payload, idx: initialValues.idx });
      onSubmit({ status: "SUCCESS", type: "NEW", message: result.data.message });
    } catch (error) {
      if (error.response) {
        onSubmit({ status: "ERROR", type: "NEW", message: error.response.data.message });
      } else {
        onSubmit({ status: "ERROR", type: "NEW", message: error.message });
      }
    } finally {
      setloading(false);
    }
  };

  const handleTextInputsChange = (field, value) => setFormData((prevState) => ({ ...prevState, [field]: value }));

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    } else {
      setFormData({});
    }
  }, [initialValues]);

  return (
    <form onSubmit={submitHandler} className={SharedStyles["sales-form"]}>
      {loading && <span className={`spinner ${SharedStyles["spinner"]}`} />}

      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridColumn: 'span 6',
        marginBottom: '20px',
      }}>
        <MyAccordion
          clickFunction={setIsOpened}
          opened={isopened}
          title={formData.idx ? 'Package 상세' : 'Adding Package'}
          fs='18px'
          fw='600'
          color='#ABABAB'
          px={0}
          icon={!(Object.keys(initialValues).length === 0) ? 'hidden' : ''}
        />
      </div>
      {/* <div>
        <label htmlFor="originator">발생기관</label>
        <input type="text" disabled value={university.name} />
      </div> */}
      {(!(Object.keys(initialValues).length === 0) ? true : isopened) &&
        <>
          <div>
            <label htmlFor="occurrence_date">적용날짜*</label>
            <DatePickerCustom
              required={true}
              data={formData.packageDate}
              onChangeDatePicker={(pickedDate) =>
                setFormData((prevState) => ({ ...prevState, packageDate: new Date(pickedDate).getTime() }))
              }
              placeholderTxt='적용날짜'
            />
          </div>
          <div
            style={{
              gridColumn: 'span 3'
            }}
          >
            <label htmlFor="fee">금액*</label>
            <input
              type="number"
              required
              id="fee"
              value={formData.fee || ''}
              onChange={(event) => handleTextInputsChange("fee", event.target.value)}
              placeholder="금액"
            />
          </div>
          <div
            style={{
              gridColumn: !(Object.keys(initialValues).length === 0) ? "span 6" : "span 5",
            }}
          >
            <label htmlFor="memo">메모</label>
            <input
              rows={5}
              id="memo"
              value={formData.memo || ''}
              onChange={(event) => handleTextInputsChange("memo", event.target.value)}
              placeholder='메모'
            />
          </div>

          <div style={{ gridColumn: !(Object.keys(initialValues).length === 0) ? "span 6" : "span 1", }}>
            <button
              style={{
                gridColumn: 'span 1'
              }}
              type="submit">
              {!(Object.keys(initialValues).length === 0) ? '수정하기' : '추가하기'}
            </button>
          </div>
        </>
      }
    </form>
  );
};

export default PackageForm;
