const EmptyArrow = ({ opened, opacity }) => {
    return (
        <span className='mx-2' style={{ opacity: opacity ? '20%' : '100%' }}>
            {opened ?
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8332 1.5L6.99984 7.33333L1.1665 1.5" stroke="#19191A" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                :
                <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.8332 7.33333L6.99984 1.5L1.1665 7.33333" stroke="#19191A" stroke-opacity="0.9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            }
        </span>
    )
}

export default EmptyArrow