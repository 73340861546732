import React, { useEffect, useState } from "react";
import DatePickerCustom from "../../../../datepicker/DatePickerCustom";
import SALES_API from "../../../../../API/annualPay/sales";
import SharedStyles from "../../../css/sales/shared.module.scss";
import MyAccordion from "../../ui/accordion/my-accordion";
import useToggle from "../../../../../hooks/toggle-hook";

const DepositeForm = ({ initialValues, onSubmit, onClose, university, disabledd, handleRefetch }) => {
  const [formData, setFormData] = useState(initialValues ?? {});
  const [loading, setloading] = useState(false);
  const [isopened, setIsOpened] = useToggle(true);

  const handleDateInputsChange = (field, pickedDate) => {
    setFormData((prev) => ({
      ...prev,
      [field]: new Date(pickedDate).getTime(),
    }));
  };
  const handleTextInputsChange = (field, value) => setFormData((prevState) => ({ ...prevState, [field]: value }));

  const submitHandler = async (event) => {
    event.preventDefault();
    setloading(true);

    let result;
    const payload = {
      company_idx: university.company_idx,
      depositor: formData.depositor,
      depositAmount: formData.depositAmount,
      memo: formData.memo,
      occurrenceDate: window.$Global.convertDate(formData.occurrenceDate).substring(0, 7),
      collectionDate: window.$Global.convertDate(formData.collectionDate),
    }
    try {
      result = !initialValues.idx ? await SALES_API.uploadNewDeposit(payload) : await SALES_API.updateDeposit({ ...payload, idx: initialValues.idx });
      onSubmit({ status: "SUCCESS", type: "NEW", message: result.data.message });
      handleRefetch()
    } catch (error) {
      if (error.response) {
        onSubmit({ status: "ERROR", type: "NEW", message: error.response.data.message });
      } else {
        onSubmit({ status: "ERROR", type: "NEW", message: error.message });
      }
    } finally {
      setloading(false);
    }
  };

  // Computed Values
  const requiredFields = ["occurrenceDate", "depositor", "collectionDate", "depositAmount"];
  const formDataIsValid = requiredFields.every((key) => formData[key]);

  useEffect(() => {
    if (initialValues) {
      setFormData(initialValues);
    } else {
      setFormData({});
    }
  }, [initialValues]);

  return (
    <form onSubmit={submitHandler} className={SharedStyles["sales-form"]}>
      {loading && <span className={`spinner ${SharedStyles["spinner"]}`} />}
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gridColumn: 'span 6',
        marginBottom: '20px',
      }}>
        <MyAccordion
          clickFunction={setIsOpened}
          opened={isopened}
          title={formData.idx ? '수금 상세' : 'Adding deposit'}
          fs='18px'
          fw='600'
          color='#ABABAB'
          px={0}
          icon={!(Object.keys(initialValues).length === 0) ? 'hidden' : ''}
        />
      </div>
      {/* <div style={{gridColumn:'span 2'}}>
        <label htmlFor="university">발생기관</label>
        <input type="text" disabled value={university.name} placeholder="발생기관" />
      </div> */}
      {(!(Object.keys(initialValues).length === 0) ? true : isopened) &&
        <>
          <div
            style={{
              gridColumn: "span 2",
            }}
          >
            <label htmlFor="depositor">입금자*</label>
            <input
              disabled={disabledd}
              type="text"
              id="depositor"
              required
              value={formData.depositor || ''}
              onChange={(event) => handleTextInputsChange("depositor", event.target.value)}
              placeholder="입금자"
            />
          </div>
          <div>
            <label htmlFor="occurrenceDate">발생날짜*</label>
            <DatePickerCustom
              disabledd={disabledd}
              data={formData.occurrenceDate}
              onChangeDatePicker={(pickedDate) => handleDateInputsChange("occurrenceDate", pickedDate)}
              placeholderTxt='발생날짜'
            />
          </div>
          <div>
            <label htmlFor="collectionDate">수금날짜*</label>
            <DatePickerCustom
              disabledd={disabledd}
              data={formData.collectionDate}
              onChangeDatePicker={(pickedDate) => handleDateInputsChange("collectionDate", pickedDate)}
              placeholderTxt="수금날짜"
            />
          </div>
          <div
            style={{
              gridColumn: "span 2",
            }}
          >
            <label htmlFor="depositAmount">금액*</label>
            <input
              disabled={disabledd}
              type="number"
              required
              id="depositAmount"
              value={formData.depositAmount || ''}
              onChange={(event) => handleTextInputsChange("depositAmount", event.target.value)}
              placeholder="금액"
              style={{
                gridColumn: "span 2",
              }}
            />
          </div>
          <div
            style={{
              gridColumn: !(Object.keys(initialValues).length === 0) ? "span 6" : "span 5",
            }}>
            <label htmlFor="memo">메모</label>
            <input
              disabled={disabledd}
              rows={5}
              id="memo"
              value={formData.memo || ''}
              onChange={(event) => handleTextInputsChange("memo", event.target.value)}
              placeholder="메모 (선택하기)"
              style={{
                height: '48px'
              }}
            />
          </div>

          <div style={{gridColumn: !(Object.keys(initialValues).length === 0) ? "span 6" : "span 1",}}>
            <button
              style={{
                gridColumn: "span 1",
                height: '48px',
                opacity: formDataIsValid ? 1 : 0.6
              }}
              disabled={!formDataIsValid || loading} type="submit">
              {!(Object.keys(initialValues).length === 0) ? '수정하기' : '추가하기'}
            </button>
          </div>
        </>
      }
    </form>
  );
};

export default DepositeForm;
