import React from 'react'
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";


const Arrow = ({opened}) => {
    return (
        <span className='mx-2'>
            {opened ?
            <TiArrowSortedDown />
            :
            <TiArrowSortedUp />
            }
        </span>
    )
}

export default Arrow